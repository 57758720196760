import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ABSIcon,
  BottomLessAbyssIcon,
  // CopyIcon,
  CrimsonFlameIcon,
  DFTIcon,
  // DrawChainIcon,
  DropOfHeavenIcon,
  // EatIcon,
  EverGreenTreeIcon,
  EXPIcon,
  FORIcon,
  GoldenLightIcon,
  INTIcon,
  MNDIcon,
  PenIcon,
  SolidRockIcon,
  UnrivaledSteelIcon,
  ImgPlaceholderIcon,
} from "../../assets/icons";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { SelectInput, TextInput } from "../../components/Form";
import Button, { CancelButton } from "../../components/Button";
// import { Switch } from "../../components/Switch";
import {
  ConfirmModal,
  ErrorModal,
  InfoModal,
  LoadingModal,
} from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchFormData } from "../../services/fetch";
import { useMutation, useQuery } from "react-query";
import { storeErrorModal } from "../../services/storage";
import { AuthContext } from "../../context/authContext";
import { useMemo } from "react";
import { distributeAbilities, isFloat } from "../../utils";
import { AppContext } from "../../context/appContext";
import { setSelectTitle } from "../../slices/titleReducer";
import { formatNumber } from "../../utils/truncate";

const elements = [
  "heavenly_dew_element", //drop_of_heaven_element
  "crimson_flame_element", //red_lotus_flame_element
  "golden_light_element",
  "eternal_tree_element", //everlasting_tree_element
  "peerless_steel_element", //warriors_steel_element
  "unfathomable_abyss_element",
  "adamantine_rock_element", //solid_rocks_element
];

const status = [
  "abs_parameter",
  "def_parameter",
  "month_parameter",
  "int_parameter",
  "exp_parameter",
];

// tag name and it's existence
const tags = { tag_1: false, tag_2: false, tag_3: false };
const tags_en = { tag_en_1: false, tag_en_2: false, tag_en_3: false };

const units = [
  {
    anima: 8,
    ability: 355,
    force: 60,
    allocate: 295,
  },
  {
    anima: 9,
    ability: 452,
    force: 76,
    allocate: 376,
  },
  {
    anima: 10,
    ability: 549,
    force: 92,
    allocate: 457,
  },
  {
    anima: 11,
    ability: 645,
    force: 108,
    allocate: 537,
  },
  {
    anima: 12,
    ability: 742,
    force: 124,
    allocate: 618,
  },
  {
    anima: 13,
    ability: 839,
    force: 140,
    allocate: 699,
  },
  {
    anima: 14,
    ability: 935,
    force: 156,
    allocate: 779,
  },
  {
    anima: 15,
    ability: 1032,
    force: 172,
    allocate: 860,
  },
  {
    anima: 16,
    ability: 1129,
    force: 189,
    allocate: 940,
  },
  {
    anima: 17,
    ability: 1226,
    force: 205,
    allocate: 1021,
  },
  {
    anima: 18,
    ability: 1322,
    force: 221,
    allocate: 1101,
  },
  {
    anima: 19,
    ability: 1419,
    force: 237,
    allocate: 1182,
  },
  {
    anima: 20,
    ability: 1516,
    force: 253,
    allocate: 1263,
  },
  {
    anima: 21,
    ability: 1612,
    force: 269,
    allocate: 1343,
  },
  {
    anima: 22,
    ability: 1709,
    force: 285,
    allocate: 1424,
  },
  {
    anima: 23,
    ability: 1806,
    force: 301,
    allocate: 1505,
  },
  {
    anima: 24,
    ability: 1902,
    force: 317,
    allocate: 1585,
  },
  {
    anima: 25,
    ability: 2000,
    force: 334,
    allocate: 1666,
  },
  {
    anima: 26,
    ability: 2040,
    force: 340,
    allocate: 1700,
  },
  {
    anima: 27,
    ability: 2080,
    force: 347,
    allocate: 1733,
  },
  {
    anima: 28,
    ability: 2120,
    force: 354,
    allocate: 1766,
  },
  {
    anima: 29,
    ability: 2160,
    force: 360,
    allocate: 1800,
  },
  {
    anima: 30,
    ability: 2200,
    force: 367,
    allocate: 1833,
  },
  {
    anima: 31,
    ability: 2240,
    force: 374,
    allocate: 1866,
  },
  {
    anima: 32,
    ability: 2280,
    force: 380,
    allocate: 1900,
  },
  {
    anima: 33,
    ability: 2320,
    force: 387,
    allocate: 1933,
  },
  {
    anima: 34,
    ability: 2360,
    force: 394,
    allocate: 1966,
  },
  {
    anima: 35,
    ability: 2400,
    force: 400,
    allocate: 2000,
  },
  {
    anima: 36,
    ability: 2440,
    force: 407,
    allocate: 2033,
  },
  {
    anima: 37,
    ability: 2480,
    force: 414,
    allocate: 2066,
  },
  {
    anima: 38,
    ability: 2520,
    force: 420,
    allocate: 2100,
  },
  {
    anima: 39,
    ability: 2560,
    force: 427,
    allocate: 2133,
  },
  {
    anima: 40,
    ability: 2600,
    force: 434,
    allocate: 2166,
  },
  {
    anima: 41,
    ability: 2640,
    force: 440,
    allocate: 2200,
  },
  {
    anima: 42,
    ability: 2680,
    force: 447,
    allocate: 2233,
  },
  {
    anima: 43,
    ability: 2720,
    force: 454,
    allocate: 2266,
  },
  {
    anima: 44,
    ability: 2760,
    force: 460,
    allocate: 2300,
  },
  {
    anima: 45,
    ability: 2800,
    force: 467,
    allocate: 2333,
  },
  {
    anima: 46,
    ability: 2840,
    force: 474,
    allocate: 2366,
  },
  {
    anima: 47,
    ability: 2880,
    force: 480,
    allocate: 2400,
  },
  {
    anima: 48,
    ability: 2920,
    force: 487,
    allocate: 2433,
  },
  {
    anima: 49,
    ability: 2960,
    force: 494,
    allocate: 2466,
  },
  {
    anima: 50,
    ability: 3000,
    force: 500,
    allocate: 2500,
  },
  {
    anima: 52,
    ability: 3040,
    force: 507,
    allocate: 2533,
  },
  {
    anima: 54,
    ability: 3080,
    force: 514,
    allocate: 2566,
  },
  {
    anima: 56,
    ability: 3120,
    force: 520,
    allocate: 2600,
  },
  {
    anima: 58,
    ability: 3160,
    force: 527,
    allocate: 2633,
  },
  {
    anima: 60,
    ability: 3200,
    force: 534,
    allocate: 2666,
  },
  {
    anima: 62,
    ability: 3240,
    force: 540,
    allocate: 2700,
  },
  {
    anima: 64,
    ability: 3280,
    force: 547,
    allocate: 2733,
  },
  {
    anima: 66,
    ability: 3320,
    force: 554,
    allocate: 2766,
  },
  {
    anima: 68,
    ability: 3360,
    force: 560,
    allocate: 2800,
  },
  {
    anima: 70,
    ability: 3400,
    force: 567,
    allocate: 2833,
  },
  {
    anima: 72,
    ability: 3440,
    force: 574,
    allocate: 2866,
  },
  {
    anima: 74,
    ability: 3480,
    force: 580,
    allocate: 2900,
  },
  {
    anima: 76,
    ability: 3520,
    force: 587,
    allocate: 2933,
  },
  {
    anima: 78,
    ability: 3560,
    force: 594,
    allocate: 2966,
  },
  {
    anima: 80,
    ability: 3600,
    force: 600,
    allocate: 3000,
  },
  {
    anima: 82,
    ability: 3640,
    force: 607,
    allocate: 3033,
  },
  {
    anima: 84,
    ability: 3680,
    force: 614,
    allocate: 3066,
  },
  {
    anima: 86,
    ability: 3720,
    force: 620,
    allocate: 3100,
  },
  {
    anima: 88,
    ability: 3760,
    force: 627,
    allocate: 3133,
  },
  {
    anima: 90,
    ability: 3800,
    force: 634,
    allocate: 3166,
  },
  {
    anima: 92,
    ability: 3840,
    force: 640,
    allocate: 3200,
  },
  {
    anima: 94,
    ability: 3880,
    force: 647,
    allocate: 3233,
  },
  {
    anima: 96,
    ability: 3920,
    force: 654,
    allocate: 3266,
  },
  {
    anima: 98,
    ability: 3960,
    force: 660,
    allocate: 3300,
  },
  {
    anima: 100,
    ability: 4000,
    force: 667,
    allocate: 3333,
  },
  {
    anima: 105,
    ability: 4050,
    force: 675,
    allocate: 3375,
  },
  {
    anima: 110,
    ability: 4100,
    force: 684,
    allocate: 3416,
  },
  {
    anima: 115,
    ability: 4150,
    force: 692,
    allocate: 3458,
  },
  {
    anima: 120,
    ability: 4200,
    force: 700,
    allocate: 3500,
  },
  {
    anima: 125,
    ability: 4250,
    force: 709,
    allocate: 3541,
  },
  {
    anima: 130,
    ability: 4300,
    force: 717,
    allocate: 3583,
  },
  {
    anima: 135,
    ability: 4350,
    force: 725,
    allocate: 3625,
  },
  {
    anima: 140,
    ability: 4400,
    force: 734,
    allocate: 3666,
  },
  {
    anima: 145,
    ability: 4450,
    force: 742,
    allocate: 3708,
  },
  {
    anima: 150,
    ability: 4500,
    force: 750,
    allocate: 3750,
  },
  {
    anima: 155,
    ability: 4550,
    force: 759,
    allocate: 3791,
  },
  {
    anima: 160,
    ability: 4600,
    force: 767,
    allocate: 3833,
  },
  {
    anima: 165,
    ability: 4650,
    force: 775,
    allocate: 3875,
  },
  {
    anima: 170,
    ability: 4700,
    force: 784,
    allocate: 3916,
  },
  {
    anima: 175,
    ability: 4750,
    force: 792,
    allocate: 3958,
  },
  {
    anima: 180,
    ability: 4800,
    force: 800,
    allocate: 4000,
  },
  {
    anima: 185,
    ability: 4850,
    force: 809,
    allocate: 4041,
  },
  {
    anima: 190,
    ability: 4900,
    force: 817,
    allocate: 4083,
  },
  {
    anima: 195,
    ability: 4950,
    force: 825,
    allocate: 4125,
  },
  {
    anima: 200,
    ability: 5000,
    force: 834,
    allocate: 4166,
  },
  {
    anima: 210,
    ability: 5050,
    force: 842,
    allocate: 4208,
  },
  {
    anima: 220,
    ability: 5100,
    force: 850,
    allocate: 4250,
  },
  {
    anima: 230,
    ability: 5150,
    force: 859,
    allocate: 4291,
  },
  {
    anima: 240,
    ability: 5200,
    force: 867,
    allocate: 4333,
  },
  {
    anima: 250,
    ability: 5250,
    force: 875,
    allocate: 4375,
  },
  {
    anima: 260,
    ability: 5300,
    force: 884,
    allocate: 4416,
  },
  {
    anima: 270,
    ability: 5350,
    force: 892,
    allocate: 4458,
  },
  {
    anima: 280,
    ability: 5400,
    force: 900,
    allocate: 4500,
  },
  {
    anima: 290,
    ability: 5450,
    force: 909,
    allocate: 4541,
  },
  {
    anima: 300,
    ability: 5500,
    force: 917,
    allocate: 4583,
  },
  {
    anima: 310,
    ability: 5550,
    force: 925,
    allocate: 4625,
  },
  {
    anima: 320,
    ability: 5600,
    force: 934,
    allocate: 4666,
  },
  {
    anima: 330,
    ability: 5650,
    force: 942,
    allocate: 4708,
  },
  {
    anima: 340,
    ability: 5700,
    force: 950,
    allocate: 4750,
  },
  {
    anima: 350,
    ability: 5750,
    force: 959,
    allocate: 4791,
  },
  {
    anima: 360,
    ability: 5800,
    force: 967,
    allocate: 4833,
  },
  {
    anima: 370,
    ability: 5850,
    force: 975,
    allocate: 4875,
  },
  {
    anima: 380,
    ability: 5900,
    force: 984,
    allocate: 4916,
  },
  {
    anima: 390,
    ability: 5950,
    force: 992,
    allocate: 4958,
  },
  {
    anima: 400,
    ability: 6000,
    force: 1000,
    allocate: 5000,
  },
  {
    anima: 420,
    ability: 6058,
    force: 1010,
    allocate: 5048,
  },
  {
    anima: 440,
    ability: 6117,
    force: 1020,
    allocate: 5097,
  },
  {
    anima: 460,
    ability: 6175,
    force: 1030,
    allocate: 5145,
  },
  {
    anima: 480,
    ability: 6234,
    force: 1039,
    allocate: 5195,
  },
  {
    anima: 500,
    ability: 6292,
    force: 1049,
    allocate: 5243,
  },
  {
    anima: 520,
    ability: 6351,
    force: 1059,
    allocate: 5292,
  },
  {
    anima: 540,
    ability: 6409,
    force: 1069,
    allocate: 5340,
  },
  {
    anima: 560,
    ability: 6468,
    force: 1078,
    allocate: 5390,
  },
  {
    anima: 580,
    ability: 6526,
    force: 1088,
    allocate: 5438,
  },
  {
    anima: 600,
    ability: 6585,
    force: 1098,
    allocate: 5487,
  },
  {
    anima: 620,
    ability: 6626,
    force: 1105,
    allocate: 5521,
  },
  {
    anima: 640,
    ability: 6668,
    force: 1112,
    allocate: 5556,
  },
  {
    anima: 660,
    ability: 6709,
    force: 1119,
    allocate: 5590,
  },
  {
    anima: 680,
    ability: 6751,
    force: 1126,
    allocate: 5625,
  },
  {
    anima: 700,
    ability: 6792,
    force: 1132,
    allocate: 5660,
  },
  {
    anima: 720,
    ability: 6834,
    force: 1139,
    allocate: 5695,
  },
  {
    anima: 740,
    ability: 6875,
    force: 1146,
    allocate: 5729,
  },
  {
    anima: 760,
    ability: 6917,
    force: 1153,
    allocate: 5764,
  },
  {
    anima: 780,
    ability: 6958,
    force: 1160,
    allocate: 5798,
  },
  {
    anima: 800,
    ability: 7000,
    force: 1167,
    allocate: 5833,
  },
  {
    anima: 820,
    ability: 7032,
    force: 1172,
    allocate: 5860,
  },
  {
    anima: 840,
    ability: 7064,
    force: 1178,
    allocate: 5886,
  },
  {
    anima: 860,
    ability: 7096,
    force: 1183,
    allocate: 5913,
  },
  {
    anima: 880,
    ability: 7128,
    force: 1188,
    allocate: 5940,
  },
  {
    anima: 900,
    ability: 7161,
    force: 1194,
    allocate: 5967,
  },
  {
    anima: 920,
    ability: 7193,
    force: 1199,
    allocate: 5994,
  },
  {
    anima: 940,
    ability: 7225,
    force: 1205,
    allocate: 6020,
  },
  {
    anima: 960,
    ability: 7257,
    force: 1210,
    allocate: 6047,
  },
  {
    anima: 980,
    ability: 7289,
    force: 1215,
    allocate: 6074,
  },
  {
    anima: 1000,
    ability: 7322,
    force: 1221,
    allocate: 6101,
  },
  {
    anima: 1020,
    ability: 7348,
    force: 1225,
    allocate: 6123,
  },
  {
    anima: 1040,
    ability: 7374,
    force: 1229,
    allocate: 6145,
  },
  {
    anima: 1060,
    ability: 7400,
    force: 1234,
    allocate: 6166,
  },
  {
    anima: 1080,
    ability: 7427,
    force: 1238,
    allocate: 6189,
  },
  {
    anima: 1100,
    ability: 7453,
    force: 1243,
    allocate: 6210,
  },
  {
    anima: 1120,
    ability: 7479,
    force: 1247,
    allocate: 6232,
  },
  {
    anima: 1140,
    ability: 7506,
    force: 1251,
    allocate: 6255,
  },
  {
    anima: 1160,
    ability: 7532,
    force: 1256,
    allocate: 6276,
  },
  {
    anima: 1180,
    ability: 7558,
    force: 1260,
    allocate: 6298,
  },
  {
    anima: 1200,
    ability: 7585,
    force: 1265,
    allocate: 6320,
  },
  {
    anima: 1220,
    ability: 7607,
    force: 1268,
    allocate: 6339,
  },
  {
    anima: 1240,
    ability: 7629,
    force: 1272,
    allocate: 6357,
  },
  {
    anima: 1260,
    ability: 7651,
    force: 1276,
    allocate: 6375,
  },
  {
    anima: 1280,
    ability: 7673,
    force: 1279,
    allocate: 6394,
  },
  {
    anima: 1300,
    ability: 7696,
    force: 1283,
    allocate: 6413,
  },
  {
    anima: 1320,
    ability: 7718,
    force: 1287,
    allocate: 6431,
  },
  {
    anima: 1340,
    ability: 7740,
    force: 1290,
    allocate: 6450,
  },
  {
    anima: 1360,
    ability: 7762,
    force: 1294,
    allocate: 6468,
  },
  {
    anima: 1380,
    ability: 7784,
    force: 1298,
    allocate: 6486,
  },
  {
    anima: 1400,
    ability: 7807,
    force: 1302,
    allocate: 6505,
  },
  {
    anima: 1420,
    ability: 7826,
    force: 1305,
    allocate: 6521,
  },
  {
    anima: 1440,
    ability: 7845,
    force: 1308,
    allocate: 6537,
  },
  {
    anima: 1460,
    ability: 7864,
    force: 1311,
    allocate: 6553,
  },
  {
    anima: 1480,
    ability: 7884,
    force: 1314,
    allocate: 6570,
  },
  {
    anima: 1500,
    ability: 7903,
    force: 1318,
    allocate: 6585,
  },
  {
    anima: 1520,
    ability: 7922,
    force: 1321,
    allocate: 6601,
  },
  {
    anima: 1540,
    ability: 7942,
    force: 1324,
    allocate: 6618,
  },
  {
    anima: 1560,
    ability: 7961,
    force: 1327,
    allocate: 6634,
  },
  {
    anima: 1580,
    ability: 7980,
    force: 1330,
    allocate: 6650,
  },
  {
    anima: 1600,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
  {
    anima: 1640,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
  {
    anima: 1680,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
  {
    anima: 1720,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
  {
    anima: 1760,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
  {
    anima: 1800,
    ability: 8000,
    force: 1334,
    allocate: 6666,
  },
];

const PersonaCreate = () => {
  const contents = translations.personaCreate;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [, setHideSideBar] = useOutletContext();
  const { selectedTitle } = useSelector((state) => state.titles);
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const { setUnexpectedErrorModal } = useContext(AppContext);
  const isUpdate = useRef(false);

  const [showKeepModal, setShowKeepModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfrimModal] = useState(false);
  const [values, setValues] = useState({
    persona_type_name: "",
    creator: "",
    persona_qty: "",
    description: "",
    for_parameter: 0,
    abs_parameter: 0,
    def_parameter: 0,
    month_parameter: 0,
    int_parameter: 0,
    exp_parameter: 0,
    eternal_tree_element: "",
    crimson_flame_element: "",
    adamantine_rock_element: "",
    peerless_steel_element: "",
    heavenly_dew_element: "",
    golden_light_element: "",
    unfathomable_abyss_element: "",
    peerage: "",
    required_anima_amount: "",
  });
  const [statusPercentages, setStatusPercentages] = useState({
    abs_parameter: "",
    def_parameter: "",
    month_parameter: "",
    int_parameter: "",
    exp_parameter: "",
  });
  const [customParameter2, setCustomParameter2] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [customParameter3, setCustomParameter3] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [customParameter1, setCustomParameter1] = useState([]);
  const [parameters1, setParameters1] = useState();
  const [image, setImage] = useState({ local: "", upload: null, hash: "" });
  const [isLoadProgress, setIsLoadProgress] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [validModal, setValidModal] = useState({ show: false, message: "" });
  const [cost, setCost] = useState(null);
  const [allocate, setAllocate] = useState(0);
  const [unit, setUnit] = useState(null);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const anmValues = useMemo(() => {
    const values = {};
    units.map((u) => {
      return (values[u.anima] = u.anima);
    });
    return values;
  }, []);

  const handleChange = (prop) => (event) => {
    if (elements.includes(prop)) {
      if (isNaN(event.target.value)) {
        return;
      }
      let total = elements.reduce((a, c) => a + Number(values[c]), 0);
      total = total - Number(values[prop]) + Number(event.target.value);
      if (total <= values.persona_qty) {
        setValues({ ...values, [prop]: Number(event.target.value) });
      }
      return;
    }
    if (status.includes(prop)) {
      if (isNaN(event.target.value)) {
        return;
      }
      const percentage = Number(event.target.value);
      if (percentage < 0 || percentage > 100) {
        return;
      }
      const total =
        status.reduce((a, c) => a + Number(statusPercentages[c]), 0) -
        statusPercentages[prop] +
        percentage;
      if (total > 100) {
        return;
      }
      const percentages = {
        ...statusPercentages,
        [prop]: percentage >= 0 ? percentage : "",
      };
      setStatusPercentages(percentages);
      // let value = (allocate * (percentage / 100)).toFixed(1);
      // setValues({
      //   ...values,
      //   [prop]: Number(parseFloat(value) % 1 === 0 ? parseInt(value) : value),
      // });
      if (!unit) {
        return;
      }
      const result = distributeAbilities(
        unit.ability,
        Object.values(percentages),
        unit.allocate
      );
      const statusValues = {};
      status.forEach((s, index) => {
        statusValues[s] = Number(result.values[index + 1]);
      });
      setValues({
        ...values,
        for_parameter: Number(result.values[0]),
        ...statusValues,
      });
      return;
    }
    if (prop === "persona_qty") {
      if (isNaN(event.target.value)) {
        return;
      }
      setValues({ ...values, [prop]: Number(event.target.value) });
      return;
    }
    if (prop === "required_anima_amount") {
      const unit = units.find((u) => u.anima === Number(event.target.value));
      setUnit(unit);
      setAllocate(unit.allocate);
      const result = distributeAbilities(
        unit.ability,
        Object.values(statusPercentages),
        unit.allocate
      );
      const statusValues = {};
      status.forEach((s, index) => {
        statusValues[s] = Number(result.values[index + 1]);
      });
      setValues({
        ...values,
        [prop]: event.target.value,
        for_parameter: Number(result.values[0]),
        ...statusValues,
      });
      // if (unit.allocate > 0) {
      //   const statusValues = {};
      //   status.forEach((s) => {
      //     let value = (unit.allocate * (statusPercentages[s] / 100)).toFixed(1);
      //     statusValues[s] = Number(
      //       parseFloat(value) % 1 === 0 ? parseInt(value) : value
      //     );
      //   });
      //   setValues({
      //     ...values,
      //     [prop]: event.target.value,
      //     for_parameter: unit.force,
      //     ...statusValues,
      //   });
      // }
      return;
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleParameter2Change = (index) => (event) => {
    setCustomParameter2({ ...customParameter2, [index]: event.target.value });
  };

  const handleParameter3Change = (index) => (event) => {
    setCustomParameter3({ ...customParameter3, [index]: event.target.value });
  };

  const handleParameter1Change = (prop, dataType) => (event) => {
    let isValid = false;
    switch (dataType) {
      case "integer":
        if (
          !isNaN(event.target.value) &&
          Number.isInteger(Number(event.target.value)) &&
          Number(event.target.value) >= 0
        ) {
          if (Number(event.target.value) > 2147483647) {
            setValidModal({
              show: true,
              message: "パラメータは最大 2147483647 まで設定可能です。",
            });
            isValid = false;
          } else {
            isValid = true;
          }
        }
        break;
      case "float":
        if (event.target.value[0] === "-") {
          isValid = false;
        } else {
          isValid = true;
        }
        break;
      case "string":
        if (typeof event.target.value === "string") {
          if (event.target.value.length > 100) {
            setValidModal({
              show: true,
              message: "文字数が上限の100文字を超えています。",
            });
            isValid = false;
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
        }
        break;
      default:
        break;
    }
    if (isValid) {
      setParameters1({ ...parameters1, [prop]: event.target.value });
    }
  };

  const fetchProgress = () =>
    fetchData({
      url: `api/job-batches/${batchId}/progress`,
    });

  const { data } = useQuery(
    ["get-persona-progress", batchId],
    () => fetchProgress(),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response?.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        if (data.data.percentage === 100) {
          setIsLoadProgress(false);
          if (data.data.totalFailedJobs) {
            setShowLoadingModal(false);
            setShowErrorModal(true);
          }
        }
      },
      refetchInterval: isLoadProgress ? 5000 : false,
      enabled: isLoadProgress,
    }
  );

  const postPersona = async (data, isSave = false) => {
    const response = await fetchData({
      url: `api/persona_type?generate=${isSave ? 0 : 1}`,
      method: "post",
      data: data,
    });
    return response;
  };

  const putPersona = async (data, isSave = false) => {
    const response = await fetchData({
      url: `api/persona_type/${id}/update?generate=${isSave ? 0 : 1}`,
      method: "put",
      data: data,
    });
    return response;
  };

  const { mutate: createPersona } = useMutation(
    async (data) => {
      return isUpdate.current ? putPersona(data) : postPersona(data);
    },
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
          storeErrorModal(err.response.data.errors);
        }
        setUnexpectedErrorModal(true);
        setShowLoadingModal(false);
      },
      onSuccess: async (data) => {
        setBatchId(data.data.batchId);
        // setProgress(80);
        setValues({
          persona_type_name: "",
          creator: "",
          persona_qty: "",
          description: "",
          for_parameter: 20,
          abs_parameter: 20,
          def_parameter: 20,
          month_parameter: 20,
          int_parameter: 20,
          exp_parameter: 20,
          eternal_tree_element: "",
          crimson_flame_element: "",
          adamantine_rock_element: "",
          peerless_steel_element: "",
          heavenly_dew_element: "",
          golden_light_element: "",
          unfathomable_abyss_element: "",
          peerage: "",
          // required_anima_amount: 12.0,
        });
        if (selectedTitle.parametrs) {
          const par = {};
          const parameters = [];
          selectedTitle.parameters.forEach((p) => {
            par[p.id] = "";
            if (p.data_type === "integer") {
              par[p.id] = 0;
            }
            parameters.push = {
              id: p.id,
              name: p.parameter_name,
              value: "",
              unit: p.unit,
              data_type: p.data_type,
            };
          });
          setParameters1(par);
          setCustomParameter1(parameters);
        }
        // setProgress(90);
        setCustomParameter2({ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" });
        setCustomParameter3({ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" });
        setImage({ local: "", upload: null, hash: "" });
        // setProgress(100);
        setIsLoadProgress(true);
      },
      onSettled: () => {
        setIsCreateLoading(false);
      },
    }
  );

  const { mutate: savePersona } = useMutation(
    async (data) => {
      return isUpdate.current
        ? putPersona(data, true)
        : postPersona(data, true);
    },
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
          storeErrorModal(err.response.data.errors);
        }
        setUnexpectedErrorModal(true);
      },
      onSuccess: async () => {
        setShowKeepModal(true);
      },
      onSettled: () => {
        setIsSaveLoading(false);
      },
    }
  );

  const postAnima = async (data) => {
    const response = await fetchData({
      url: "api/persona/calculate-anima",
      method: "post",
      data: data,
    });
    return response;
  };

  const { mutate: calculateAnima } = useMutation(
    async (data) => postAnima(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
          storeErrorModal(err.response.data.errors);
        }
        setUnexpectedErrorModal(true);
      },
      onSuccess: async (data) => {
        setCost(data.data.animaAmount);
      },
    }
  );

  const fetchPersona = (id) =>
    fetchData({
      url: `api/persona_type/${id}`,
    });

  const { isLoadingPersona } = useQuery(
    ["get-persona", id],
    () => fetchPersona(id),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        const persona = data?.data?.persona_type;
        if (persona.persona_token_count > 0) {
          navigate(`/personas/${persona.id}`);
        }
        dispatch(
          setSelectTitle({
            ...persona.group,
            parameters: persona.custom_parameter1,
          })
        );
        const unit = units.find(
          (u) => u.anima === Number(persona.required_anima_amount)
        );
        setUnit(unit);
        setAllocate(unit?.allocate);
        const tagValues = {};
        // Is tag exist or not
        for (const t in tags) {
          if (!!persona[t] || !!persona[`tag_en_${t.slice(-1)}`]) {
            tags[t] = true;
            tags_en[`tag_en_${t.slice(-1)}`] = true;
            tagValues[t] = persona[t] ?? "";
            tagValues[`tag_en_${t.slice(-1)}`] =
              persona[`tag_en_${t.slice(-1)}`] ?? "";
          }
        }
        setValues({
          ...values,
          persona_type_name: persona.persona_type_name ?? "",
          creator: persona.creator ?? "",
          description: persona.description ?? "",
          for_parameter: persona.for_parameter ?? "",
          abs_parameter: persona.abs_parameter ?? "",
          def_parameter: persona.def_parameter ?? "",
          month_parameter: persona.month_parameter ?? "",
          int_parameter: persona.int_parameter ?? "",
          exp_parameter: persona.exp_parameter ?? "",
          peerage: persona.peerage ?? "",
          required_anima_amount: persona.required_anima_amount,
          eternal_tree_element: persona.saved_eternal_tree_element ?? "",
          crimson_flame_element: persona.saved_crimson_flame_element ?? "",
          adamantine_rock_element: persona.saved_adamantine_rock_element ?? "",
          peerless_steel_element: persona.saved_peerless_steel_element ?? "",
          heavenly_dew_element: persona.saved_heavenly_dew_element ?? "",
          golden_light_element: persona.saved_golden_light_element ?? "",
          unfathomable_abyss_element:
            persona.saved_unfathomable_abyss_element ?? "",
          persona_qty: persona.saved_persona_qty ?? "",
          ...tagValues,
        });
        setImage({
          local: persona.image_uri,
          upload: null,
          hash: persona.image_hash,
        });
        setCustomParameter2({
          0: persona.custom_parameter2[0] ?? "",
          1: persona.custom_parameter2[1] ?? "",
          2: persona.custom_parameter2[2] ?? "",
          3: persona.custom_parameter2[3] ?? "",
          4: persona.custom_parameter2[4] ?? "",
          5: persona.custom_parameter2[5] ?? "",
        });
        setCustomParameter3({
          0: persona.custom_parameter3[0] ?? "",
          1: persona.custom_parameter3[1] ?? "",
          2: persona.custom_parameter3[2] ?? "",
          3: persona.custom_parameter3[3] ?? "",
          4: persona.custom_parameter3[4] ?? "",
          5: persona.custom_parameter3[5] ?? "",
        });
        setStatusPercentages({
          abs_parameter: persona.weights[0] ?? "",
          def_parameter: persona.weights[1] ?? "",
          month_parameter: persona.weights[2] ?? "",
          int_parameter: persona.weights[3] ?? "",
          exp_parameter: persona.weights[4] ?? "",
        });
      },
      enabled: isUpdate.current,
    }
  );

  const { mutate: remove, isLoading: removeIsLoading } = useMutation(
    async (id) => {
      return fetchData({
        url: `api/persona_type/${id}`,
        method: "delete",
      });
    },
    {
      onSuccess: async () => {
        navigate("/personas");
      },
      onError: async (err) => {
        setUnexpectedErrorModal(true);
      },
    }
  );

  useEffect(() => {
    if (location.pathname.includes("/personas/update") && id) {
      isUpdate.current = true;
    }
  }, [id, location.pathname]);

  // useEffect(() => {
  //   if (Number(values.persona_qty) * cost > Number(user.tenant.anm_amount)) {
  //     setIsValid(false);
  //     setValidModal({ show: true, message: "ANM残高が不足しています。" });
  //   } else {
  //     setIsValid(true);
  //   }
  // }, [cost, user?.tenant.anm_amount, values.persona_qty]);

  useEffect(() => {
    const isRun = status.every((c) => values[c] > 0);
    if (isRun) {
      calculateAnima({
        abs: values.abs_parameter,
        dft: values.def_parameter,
        mnd: values.month_parameter,
        int: values.int_parameter,
        exp: values.exp_parameter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.abs_parameter,
    values.def_parameter,
    values.month_parameter,
    values.int_parameter,
    values.exp_parameter,
  ]);

  useEffect(() => {
    setHideSideBar(true);
  }, [setHideSideBar]);

  useEffect(() => {
    if (!isUpdate.current && !selectedTitle) {
      navigate("/personas/select");
    }
    if (selectedTitle && selectedTitle.parameters) {
      const par = {};
      const parameters = [];
      selectedTitle.parameters.forEach((p) => {
        par[p.id] = isUpdate.current && p.value ? p.value : "";
        parameters.push({
          id: p.id,
          name: p.parameter_name ?? p.name,
          value: "",
          unit: p.unit,
          data_type: p.data_type,
        });
      });
      setParameters1(par);
      setCustomParameter1(parameters);
    }
  }, [location.pathname, navigate, selectedTitle]);

  let loading = false;

  if (isUpdate.current && isLoadingPersona) {
    loading = true;
  } else if (!selectedTitle) {
    loading = true;
  }

  const handleImageChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check file type
      const allowedTypes = ["image/jpeg", "image/png", "application/json"];
      if (!allowedTypes.includes(file.type)) {
        setValidModal({
          show: true,
          message: (
            <>
              アップロードできるファイル形式は jpg、png、json <br />
              のみです。
            </>
          ),
        });
        return;
      }
      if (file.size > 2 * 1000 ** 2) {
        setValidModal({
          show: true,
          message: contents.validation.imageSize,
        });
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImage({ local: imageUrl, upload: file, hash: "" });
    }
  };

  const postImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image.upload);
      formData.append("type", "ipfs");
      const res = await fetchFormData({
        url: "api/upload",
        data: formData,
      });
      return {
        uri: res.data.url,
        hash: res.data.ipfs.Hash,
      };
    } catch (error) {
      console.log(error);
      setValidModal({
        show: true,
        message: "Error in the upload form",
      });
      return;
    }
  };

  const handleCreate = async () => {
    setIsCreateLoading(true);
    if (
      !values.persona_type_name ||
      !values.creator ||
      !values.persona_qty ||
      (!isUpdate.current && !image.upload) ||
      !values.for_parameter ||
      !values.abs_parameter ||
      !values.def_parameter ||
      !values.month_parameter ||
      !values.int_parameter ||
      !values.exp_parameter
    ) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      setIsCreateLoading(false);
      return;
    }
    if (Number(values.persona_qty) * cost > Number(user.tenant.anm_amount)) {
      setValidModal({ show: true, message: "ANM残高が不足しています。" });
      setIsCreateLoading(false);
      return;
    }
    let invalid = false;
    let type_invalid = false;
    let parameters = [];
    let invalid_tag_no;
    let invalid_tags = 0;
    for (const tag of Object.keys(tags)) {
      let exist_tag = !!values[tag];
      let exist_tag_en = !!values[`tag_en_${tag.slice(-1)}`];
      if ((exist_tag || exist_tag_en) && !(exist_tag && exist_tag_en)) {
        invalid_tag_no = tag.slice(-1);
        break;
      }
      if (!exist_tag && !exist_tag_en) {
        invalid = ++invalid_tags === 3;
      }
    }
    if (invalid_tag_no) {
      setValidModal({
        show: true,
        message: `tag ${invalid_tag_no} フィールドに入力してください。`,
      });
      setIsCreateLoading(false);
      return;
    }
    if (invalid) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      setIsCreateLoading(false);
      return;
    }
    if (customParameter1.length > 0) {
      parameters = customParameter1.map((p) => {
        let value = parameters1[p.id];
        if (value === "") {
          invalid = true;
        }
        const type = p.data_type;
        switch (type) {
          case "integer":
            if (value[value.length - 1] === "." || Number(value) <= 0) {
              type_invalid = true;
            } else {
              value = Number(value);
            }
            break;
          case "float":
            if (!isFloat(Number(value))) {
              type_invalid = true;
            } else {
              value = Number(value);
            }
            break;
          // case "string":
          //   if (!isNaN(value)) {
          //     type_invalid = true;
          //   }
          //   break;
          default:
            break;
        }
        return {
          ...p,
          value: value,
        };
      });
    }
    if (invalid) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      setIsCreateLoading(false);
      return;
    }
    if (type_invalid) {
      setValidModal({
        show: true,
        message: "カスタムパラメータの値が不正です。",
      });
      setIsCreateLoading(false);
      return;
    }
    const statusPercentageTotal = status.reduce(
      (a, c) => a + Number(statusPercentages[c]),
      0
    );
    if (statusPercentageTotal !== 100) {
      setValidModal({
        show: true,
        message: "パラメータのパーセンテージを 合計100%入力してください。",
      });
      setIsCreateLoading(false);
      return;
    }
    const elementsTotal = elements.reduce((a, c) => a + Number(values[c]), 0);
    if (elementsTotal !== values.persona_qty) {
      setValidModal({
        show: true,
        message: "発行数がelementsの合計数と一致していません。",
      });
      setIsCreateLoading(false);
      return;
    }
    setShowLoadingModal(true);
    const persona = {
      ...values,
      aura_amount: selectedTitle.aura_amount,
      group_id: selectedTitle.id,
      custom_parameter1: parameters,
      custom_parameter2: Object.values(customParameter2),
      custom_parameter3: Object.values(customParameter3),
      required_anima_amount: Number(values.required_anima_amount),
      weights: Object.values(statusPercentages),
    };
    if (image.upload) {
      const imageResponse = await postImage();
      if (!imageResponse) {
        setIsCreateLoading(false);
        return;
      }
      const { uri, hash } = imageResponse;
      persona.image_uri = uri;
      persona.image_hash = hash;
    } else if (isUpdate.current && image.local) {
      persona.image_uri = image.local;
      persona.image_hash = image.hash;
    }
    createPersona(persona);
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    let invalid = false;
    let type_invalid = false;
    let parameters = [];
    // Object.keys(tags).forEach((tag) => {
    //   if (values[tag]) {
    //     if (!values[`tag_en_${tag.slice(-1)}`]) {
    //       invalid = true;
    //       return;
    //     }
    //   } else if (values[`tag_en_${tag.slice(-1)}`]) {
    //     invalid = true;
    //     return;
    //   }
    // });
    // let invalid_tag_no;
    // let invalid_tags = 0;
    // for (const tag of Object.keys(tags)) {
    //   let exist_tag = !!values[tag];
    //   let exist_tag_en = !!values[`tag_en_${tag.slice(-1)}`];
    //   if ((exist_tag || exist_tag_en) && !(exist_tag && exist_tag_en)) {
    //     invalid_tag_no = tag.slice(-1);
    //     break;
    //   }
    //   if (!exist_tag && !exist_tag_en) {
    //     invalid = ++invalid_tags === 3;
    //   }
    // }
    // if (invalid_tag_no) {
    //   setValidModal({
    //     show: true,
    //     message: `tag ${invalid_tag_no} フィールドに入力してください。`,
    //   });
    //   setIsSaveLoading(false);
    //   return;
    // }
    if (invalid) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      setIsSaveLoading(false);
      return;
    }
    if (customParameter1.length > 0) {
      parameters = customParameter1.map((p) => {
        let value = parameters1[p.id];
        if (value === "") {
          return {
            ...p,
            value: "",
          };
        }
        const type = p.data_type;
        switch (type) {
          case "integer":
            if (value[value.length - 1] === "." || Number(value) <= 0) {
              type_invalid = true;
            } else {
              value = Number(value);
            }
            break;
          case "float":
            if (!isFloat(Number(value))) {
              type_invalid = true;
            } else {
              value = Number(value);
            }
            break;
          default:
            break;
        }
        return {
          ...p,
          value: value,
        };
      });
    }
    if (type_invalid) {
      setValidModal({
        show: true,
        message: "カスタムパラメータの値が不正です。",
      });
      setIsSaveLoading(false);
      return;
    }
    const persona = {
      ...values,
      aura_amount: selectedTitle.aura_amount,
      group_id: selectedTitle.id,
      custom_parameter1: parameters,
      custom_parameter2: Object.values(customParameter2),
      custom_parameter3: Object.values(customParameter3),
      required_anima_amount: Number(values.required_anima_amount),
      weights: Object.values(statusPercentages),
    };
    if (image.upload) {
      // const { uri, hash } = await postImage();
      const imageResponse = await postImage();
      if (!imageResponse) {
        setIsSaveLoading(false);
        return;
      }
      const { uri, hash } = imageResponse;
      persona.image_uri = uri;
      persona.image_hash = hash;
    } else if (isUpdate.current && image.local) {
      persona.image_uri = image.local;
      persona.image_hash = image.hash;
    }
    savePersona(persona);
  };

  return (
    <ContentWrapper
      titletopmarign="true"
      title={strings.formatString(contents.title)}
      topSpace={
        <div className="d-flex justify-content-end mb-6-px gap-4">
          <CancelButton
            text={contents.cancel_btn}
            width="173px"
            height="40px"
            onClick={() => setShowCancelModal(true)}
          />
          {isUpdate.current && (
            <Button
              text={contents.delete_btn}
              width="173px"
              height="40px"
              onClick={() => setShowDeleteConfrimModal(true)}
              disabled={loading || removeIsLoading}
            />
          )}
          <Button
            text={contents.keep_btn}
            width="173px"
            height="40px"
            onClick={handleSave}
            disabled={loading || isSaveLoading}
          />
          <Button
            text={contents.issue_btn}
            width="173px"
            height="40px"
            onClick={handleCreate}
            disabled={loading || isCreateLoading}
          />
        </div>
      }
      marginLeft="12px"
    >
      {loading ? (
        <em>Loading...</em>
      ) : (
        <div className="h-100 mx-36-px my-10-px">
          <section className="row mb-4 w-95">
            <p className="font-notosans-jp label-text col-2">
              {contents.title_name}
            </p>
            <p className="fs-17-px text-gray col">{selectedTitle?.name}</p>
          </section>
          <section className="row mb-4 align-items-center w-80">
            <label className="font-notosans-jp label-text col-3">
              {contents.name}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center col" style={{ gap: 20 }}>
              <TextInput
                width="415px"
                inputClass="left-placeholder fs-20-px"
                inactive
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.name_placeholder}
                value={values.persona_type_name}
                onChange={handleChange("persona_type_name")}
              />
            </div>
          </section>
          <section className="row mb-4 align-items-center w-80">
            <label className="font-notosans-jp label-text col-3">
              {contents.creator_name}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center col" style={{ gap: 20 }}>
              <TextInput
                width="415px"
                inputClass="left-placeholder fs-20-px"
                inactive
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.creator_name_placeholder}
                value={values.creator}
                onChange={handleChange("creator")}
              />
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text">
              {contents.quantity}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
            </label>
            <div className="d-flex align-items-center my-20-px">
              <TextInput
                width="230px"
                inputClass="fs-20-px center-placeholder"
                inactive
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.quantity_placeholder}
                value={values.persona_qty}
                onChange={handleChange("persona_qty")}
              />
            </div>
          </section>
          <section className="d-flex mb-5">
            <div className="">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="font-notosans-jp label-text">
                  {contents.image}
                  <span className="text-danger required-label ml-10-px fs-18-px">
                    *
                  </span>
                </p>
                <input
                  type="file"
                  id="image"
                  style={{ visibility: "hidden", display: "none" }}
                  onChange={handleImageChange}
                />
                <Button
                  text={contents.upload_btn}
                  width="159.1px"
                  height="40px"
                  onClick={() => {
                    document.getElementById("image").click();
                  }}
                />
              </div>
              {image.local ? (
                <div className="persona-img-container img-container">
                  <img src={image.local} alt="" />
                </div>
              ) : (
                <div className="persona-img-container d-flex justify-content-center align-items-center">
                  <ImgPlaceholderIcon />
                </div>
              )}
            </div>
            <div className="ms-5 w-100">
              <p
                className="font-notosans-jp label-text mb-4"
                style={{ height: 40, alignContent: "center" }}
              >
                {contents.parameter}
                <span className="fs-14-px text-light-primary ml-40-px">
                  {contents.parameter_secondary}
                </span>
              </p>
              <div className="ml-10-px">
                {customParameter1 &&
                  customParameter1.length > 0 &&
                  parameters1 &&
                  customParameter1.map((p, index) => (
                    <div className="d-flex my-3 align-items-center" key={index}>
                      <label className="label-text" style={{ width: 270 }}>
                        {p.name}
                        <span className="text-danger required-label ml-10-px fs-18-px">
                          *
                        </span>
                      </label>
                      <div className="d-flex align-items-center gap-20-px">
                        <TextInput
                          width="270px"
                          inputClass="text-center fs-17-px large-placeholder text-form-control"
                          inactive
                          placeholder="No Data"
                          isRightIcon
                          rightIcon={<PenIcon />}
                          onChange={handleParameter1Change(p.id, p.data_type)}
                          value={parameters1[p.id]}
                        />
                        <p className="fs-17-px text-dark-gray">
                          {p.unit ?? "単位"}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.description}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.description_secondary}
              </span>
            </label>
            <TextInput
              className="ml-30-px"
              isTextArea
              width="965px"
              height="118px"
              inputClass="left-placeholder"
              inactive
              placeholder={contents.description_placeholder}
              value={values.description}
              onChange={handleChange("description")}
            />
          </section>
          <section className="mb-5">
            <p className="font-notosans-jp label-text mb-4">
              {contents.tag}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.tag_secondary}
              </span>
            </p>
            <div className="d-flex align-items-center ml-20-px">
              <p className="text-white fs-18-px w-2">JP</p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                }}
                className="ml-15-px"
              >
                {Object.keys(tags).map((tag, index) => {
                  // if (isUpdate.current && !tags[tag]) return null;
                  return (
                    <div key={index} className="position-relative">
                      <TextInput
                        leftIcon={<span className="text-white">#</span>}
                        width="300px"
                        inputClass="fs-17-px text-form-control text-center large-placeholder pl-30-px"
                        inactive
                        isRightIcon={true}
                        rightIcon={<PenIcon />}
                        placeholder={contents.parameter_placeholder}
                        onChange={handleChange(tag)}
                        value={values[tag] ?? ""}
                      />
                      <span className="center-vertical-line"></span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex align-items-center ml-20-px mt-30-px">
              <p className="text-white fs-18-px w-2">EN</p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                }}
                className="ml-15-px"
              >
                {Object.keys(tags_en).map((tag, index) => {
                  // if (isUpdate.current && !tags_en[tag]) return null;
                  return (
                    <TextInput
                      key={index}
                      leftIcon={<span className="text-white">#</span>}
                      width="300px"
                      inputClass="fs-17-px text-form-control text-center large-placeholder pl-30-px"
                      inactive
                      isRightIcon={true}
                      rightIcon={<PenIcon />}
                      placeholder={contents.parameter_placeholder}
                      onChange={handleChange(tag)}
                      value={values[tag] ?? ""}
                    />
                  );
                })}
              </div>
            </div>
          </section>
          <section className="mb-5">
            <p className="font-notosans-jp label-text mb-4">
              {contents.status}
              <span className="text-danger required-label ml-10-px fs-18-px">
                *
              </span>
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.status_secondary}
              </span>
            </p>
            <section className="mx-5">
              <label
                className="font-notosans-jp label-text mb-4"
                htmlFor="required_anima_amount"
              >
                {contents.cost}
                <span className="text-danger required-label ml-10-px fs-18-px">
                  *
                </span>
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.cost_secondary}
                </span>
              </label>
              <div className="d-flex align-items-center">
                <SelectInput
                  id="required_anima_amount"
                  value={values.required_anima_amount}
                  onChange={handleChange("required_anima_amount")}
                  options={anmValues}
                  placeholder=""
                  styles={{ width: 196 }}
                  className="text-highlight text-center fs-20-px font-ethno-normal"
                />
                <p className="fs-16-px font-ethno-light text-dark-gray ml-10-px mr-50-px">
                  ANM
                </p>
                <p className="text-dark-gray">
                  <span className="fs-12-px font-notosans-jp-light">
                    {contents.cost_current}
                  </span>
                  <span className="fs-16-px font-ethno-light ml-10-px d-inline-block">
                    ／ {formatNumber(user?.tenant?.anm_amount)} ANM
                  </span>
                </p>
              </div>
              <p className="my-4">
                <span className="font-notosans-jp label-text ml-30-px mr-50-px fs-20-px">
                  <FORIcon /> FOR
                </span>
                <span className="font-ethno-normal text-light fs-20-px ml-10-px mr-30-px">
                  {values.for_parameter}
                </span>
                <span className="text-dark-gray fs-14-px font-notosans-jp-light ml-10-px d-inline-block">
                  FOR の値は直接入力できません。ANMコストにより自動算出
                </span>
              </p>
            </section>
            <section className="ms-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.parameter_label}
                <span className="text-danger required-label ml-10-px fs-18-px">
                  *
                </span>
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.parameter_label_secondary}
                </span>
              </p>
              <div className="d-flex gap-1 text-dark-gray align-items-center">
                <p className="text-center w-35">{contents.parameter_total}</p>
                <p className="text-end font-ethno-normal fs-20-px w-10">
                  {formatNumber(allocate)}
                </p>
                <p className="text-light-primary fs-14-px ml-10-px">
                  {contents.parameter_note}
                </p>
              </div>
              <div className="d-flex align-items-center my-3">
                <label className="font-notosans-jp label-text fs-20-px w-15 pl-30-px">
                  <ABSIcon /> ABS
                </label>
                <div className="d-flex align-items-center w-20">
                  <TextInput
                    width="110.46px"
                    inputClass="text-form-control-value text-light large-placeholder"
                    inactive
                    isRightIcon
                    rightIcon={<PenIcon />}
                    placeholder="---"
                    value={statusPercentages.abs_parameter}
                    onChange={handleChange("abs_parameter")}
                  />
                  <span className="text-dark-gray ont-ethno-normal fs-16-px ml-10-px">
                    %
                  </span>
                </div>
                <p className="text-highlight text-end fs-20-px font-ethno-normal w-10">
                  {formatNumber(values.abs_parameter)}
                </p>
              </div>
              <div className="d-flex align-items-center my-3">
                <label className="font-notosans-jp label-text fs-20-px w-15 pl-30-px">
                  <DFTIcon /> DFT
                </label>
                <div className="d-flex align-items-center w-20">
                  <TextInput
                    width="110.46px"
                    inputClass="text-form-control-value text-light"
                    inactive
                    isRightIcon
                    rightIcon={<PenIcon />}
                    placeholder="---"
                    value={statusPercentages.def_parameter}
                    onChange={handleChange("def_parameter")}
                  />
                  <span className="text-dark-gray ont-ethno-normal fs-16-px ml-10-px">
                    %
                  </span>
                </div>
                <p className="text-highlight text-end fs-20-px font-ethno-normal w-10">
                  {formatNumber(values.def_parameter)}
                </p>
              </div>
              <div className="d-flex align-items-center my-3">
                <label className="font-notosans-jp label-text fs-20-px w-15 pl-30-px">
                  <MNDIcon /> MND
                </label>
                <div className="d-flex align-items-center w-20">
                  <TextInput
                    width="110.46px"
                    inputClass="text-form-control-value text-light"
                    inactive
                    isRightIcon
                    rightIcon={<PenIcon />}
                    placeholder="---"
                    value={statusPercentages.month_parameter}
                    onChange={handleChange("month_parameter")}
                  />
                  <span className="text-dark-gray ont-ethno-normal fs-16-px ml-10-px">
                    %
                  </span>
                </div>
                <p className="text-highlight text-end fs-20-px font-ethno-normal w-10">
                  {formatNumber(values.month_parameter)}
                </p>
              </div>
              <div className="d-flex align-items-center my-3">
                <label className="font-notosans-jp label-text fs-20-px w-15 pl-30-px">
                  <INTIcon /> INT
                </label>
                <div className="d-flex align-items-center w-20">
                  <TextInput
                    width="110.46px"
                    inputClass="text-form-control-value text-light"
                    inactive
                    isRightIcon
                    rightIcon={<PenIcon />}
                    placeholder="---"
                    value={statusPercentages.int_parameter}
                    onChange={handleChange("int_parameter")}
                  />
                  <span className="text-dark-gray ont-ethno-normal fs-16-px ml-10-px">
                    %
                  </span>
                </div>
                <p className="text-highlight text-end fs-20-px font-ethno-normal w-10">
                  {formatNumber(values.int_parameter)}
                </p>
              </div>
              <div className="d-flex align-items-center my-3">
                <label className="font-notosans-jp label-text fs-20-px w-15 pl-30-px">
                  <EXPIcon /> EXP
                </label>
                <div className="d-flex align-items-center w-20">
                  <TextInput
                    width="110.46px"
                    inputClass="text-form-control-value text-light"
                    inactive
                    isRightIcon
                    rightIcon={<PenIcon />}
                    placeholder="---"
                    value={statusPercentages.exp_parameter}
                    onChange={handleChange("exp_parameter")}
                  />
                  <span className="text-dark-gray ont-ethno-normal fs-16-px ml-10-px">
                    %
                  </span>
                </div>
                <p className="text-highlight text-end fs-20-px font-ethno-normal w-10">
                  {formatNumber(values.exp_parameter)}
                </p>
              </div>
              <p className="pl-30-px fs-14-px text-light-primary">
                {contents.parameter_warning}
              </p>
            </section>
          </section>
          {/* <section className="mb-5">
          <p className="font-notosans-jp label-text mb-4">
            {contents.absorb.label}
            <span className="fs-14-px text-light-primary ml-50-px">
              {contents.absorb_secondary}
            </span>
          </p>
          <div className="pl-30-px d-flex">
            <label className="label-text fs-18-px w-30">
              <EatIcon className="mr-10-px" /> {contents.absorb.contract}
            </label>
            <div>
              <span
                className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                style={{ width: 50 }}
              >
                OFF
              </span>
              <Switch id="absorb" />
            </div>
          </div>
        </section>
        <section className="mb-5">
          <p className="font-notosans-jp label-text mb-4">
            {contents.draw_chain.label}
            <span className="fs-14-px text-light-primary ml-50-px">
              {contents.draw_chain_secondary}
            </span>
          </p>
          <div className="d-flex pl-30-px">
            <label className="label-text fs-18-px col-4 w-30">
              <DrawChainIcon className="mr-5-px" />
              {contents.draw_chain.contract}
            </label>
            <div className="">
              <span
                className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                style={{ width: 50 }}
              >
                ON
              </span>
              <Switch id="drawChain" />
            </div>
          </div>
          <section className="ml-30-px pl-33-px">
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px w-29">
                {contents.draw_chain.id}
              </label>
              <div className="p-0">
                <p className="font-ethno-light fs-18-px text-gray">
                  <span className="fs-19-px">
                    128849281025 <CopyIcon />
                  </span>
                  <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                    {contents.token_id_secondary}
                  </span>
                </p>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px w-25">
                {contents.draw_chain.name}
              </label>
              <div className="p-0">
                <TextInput
                  width="415px"
                  inputClass="left-placeholder text-form-control"
                  inactive
                  isRightIcon={true}
                  rightIcon={<PenIcon />}
                  placeholder={contents.draw_chain.name_placeholder}
                />
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="label-text fs-18-px d-flex pr-0-px w-25">
                <span>{contents.draw_chain.required}</span>
                <span
                  className="fs-20-px d-flex align-items-center"
                  style={{ marginLeft: "10%" }}
                >
                  <FORIcon /> FOR
                </span>
              </label>
              <div className="p-0 d-flex align-items-center">
                <TextInput
                  className="d-inline-block"
                  width="111px"
                  inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                  inactive
                  isRightIcon={true}
                  rightIcon={<PenIcon />}
                  placeholder={contents.draw_chain.placeholder}
                />
                <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                  {contents.draw_chain.required_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <label className="col-3 label-text fs-18-px w-25">
                {contents.draw_chain.no}
              </label>
              <div className="p-0 d-flex align-items-center">
                <TextInput
                  className="d-inline-block"
                  width="111px"
                  inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                  inactive
                  isRightIcon={true}
                  rightIcon={<PenIcon />}
                  placeholder={contents.draw_chain.placeholder}
                />
                <span className="font-notosans-jp fs-17-px text-gray ml-10-px">
                  {contents.draw_chain.no_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex mt-4">
              <label className="label-text fs-18-px pt-10-px w-25">
                {contents.draw_chain.description}
              </label>
              <div className="p-0 w-75">
                <TextInput
                  isTextArea
                  width="710px"
                  height="118px"
                  inputClass="left-placeholder text-form-control fs-17-px"
                  inactive
                  isRightIcon={true}
                  rightIcon={<PenIcon />}
                  placeholder={contents.draw_chain.description_placeholder}
                />
              </div>
            </div>
          </section>
        </section> */}
          <section className="mb-5">
            <p className="font-notosans-jp label-text mb-4">
              {contents.element.label}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.element_secondary}
              </span>
            </p>
            <div className="row pl-30-px">
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <DropOfHeavenIcon className="mr-10-px" />
                    天の雫
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.heavenly_dew_element}
                      onChange={handleChange("heavenly_dew_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <EverGreenTreeIcon className="mr-10-px" />
                    常磐なる樹
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.eternal_tree_element}
                      onChange={handleChange("eternal_tree_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <SolidRockIcon className="mr-10-px" />
                    堅牢な岩
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.adamantine_rock_element}
                      onChange={handleChange("adamantine_rock_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <CrimsonFlameIcon className="mr-10-px" />
                    紅蓮の焔
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.crimson_flame_element}
                      onChange={handleChange("crimson_flame_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <UnrivaledSteelIcon className="mr-10-px" />
                    無双の鋼
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.peerless_steel_element}
                      onChange={handleChange("peerless_steel_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <GoldenLightIcon className="mr-10-px" />
                    黄金の光
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.golden_light_element}
                      onChange={handleChange("golden_light_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="font-notosans-jp label-text col-6">
                    <BottomLessAbyssIcon className="mr-10-px" />
                    底知れぬ深淵
                  </label>
                  <div className="col-6 d-flex align-items-center">
                    <TextInput
                      width="110px"
                      inputClass="text-center font-ethno-normal fs-16-px large-placeholder"
                      inactive
                      placeholder="---"
                      value={values.unfathomable_abyss_element}
                      onChange={handleChange("unfathomable_abyss_element")}
                    />
                    <PenIcon className="ml-10-px" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.place_of_birth}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.place_of_birth_secondary}
              </span>
            </label>
            <p className="font-ethno-normal fs-19-px text-gray ml-30-px">
              {user?.tenant?.birthplace}
            </p>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.peerage}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.peerage_secondary}
              </span>
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.peerage_third}
              </span>
            </label>
            <SelectInput
              id="peerage"
              value={values.peerage}
              onChange={handleChange("peerage")}
              options={{
                Duke: "duke",
                Marquess: "marquess",
                Count: "count",
                Viscount: "viscount",
                Baron: "baron",
              }}
              placeholder=""
              styles={{ width: 307.92, marginLeft: 30 }}
              className="font-ethno-normal text-gray fs-17-px"
            />
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.parameter_ability}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.parameter_ability_secondary}
              </span>
            </label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1.5rem",
              }}
              className="ml-30-px"
            >
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[0]}
                onChange={handleParameter2Change(0)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[1]}
                onChange={handleParameter2Change(1)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[2]}
                onChange={handleParameter2Change(2)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[3]}
                onChange={handleParameter2Change(3)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[4]}
                onChange={handleParameter2Change(4)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter2[5]}
                onChange={handleParameter2Change(5)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
            </div>
          </section>
          <section className="mb-5">
            <label className="font-notosans-jp label-text mb-4">
              {contents.parameter_title}
              <span className="fs-14-px text-light-primary ml-50-px">
                {contents.parameter_title_secondary}
              </span>
            </label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1.5rem",
              }}
              className="ml-30-px"
            >
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[0]}
                onChange={handleParameter3Change(0)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[1]}
                onChange={handleParameter3Change(1)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[2]}
                onChange={handleParameter3Change(2)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[3]}
                onChange={handleParameter3Change(3)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[4]}
                onChange={handleParameter3Change(4)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
              <TextInput
                width="300px"
                inputClass="fs-17-px text-form-control text-center large-placeholder"
                inactive
                value={customParameter3[5]}
                onChange={handleParameter3Change(5)}
                isRightIcon={true}
                rightIcon={<PenIcon />}
                placeholder={contents.parameter_placeholder}
              />
            </div>
          </section>
        </div>
      )}
      <InfoModal
        isOpen={showKeepModal}
        onAction={() => {
          setShowKeepModal(false);
          setTimeout(() => {
            navigate("/personas");
          }, 500);
        }}
      >
        保存しました。
      </InfoModal>
      <ConfirmModal
        isOpen={showCancelModal}
        onConfirm={() => {
          setShowCancelModal(false);
          handleSave();
        }}
        onCancel={() => {
          setShowCancelModal(false);
          setTimeout(() => {
            navigate("/personas");
          }, 500);
        }}
      >
        一部の変更は保存されていません。
        <br />
        変更を保存しますか？
      </ConfirmModal>
      <ConfirmModal
        isOpen={showDeleteConfirmModal}
        onConfirm={() => {
          setShowDeleteConfrimModal(false);
          remove(id);
        }}
        onCancel={() => setShowDeleteConfrimModal(false)}
        cancelText="キャンセル"
        confirmText="削除する"
      >
        このペルリアを削除しますか？
      </ConfirmModal>
      <LoadingModal
        className="generate-loading"
        isOpen={showLoadingModal}
        isVideoBackground
        onAction={() => {
          setShowLoadingModal(false);
          setTimeout(() => {
            navigate("/personas");
          }, 500);
        }}
        progress={data?.data?.percentage ?? 0}
      />
      <InfoModal
        isOpen={validModal.show}
        onAction={() => {
          setValidModal({ ...validModal, show: false });
        }}
      >
        {validModal.message}
      </InfoModal>
      <ErrorModal
        isOpen={showErrorModal}
        onAction={() => setShowErrorModal(false)}
      >
        mintのエラーが発生しました。
      </ErrorModal>
    </ContentWrapper>
  );
};

export default PersonaCreate;
