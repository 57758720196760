import { Link, useNavigate } from "react-router-dom";
import ContentWrapper from "../../layouts/ContentWrapper";
import { useContext, useState } from "react";
import { translations } from "../../services/localization";
import Pagination from "../../components/common/Pagination";
import Button from "../../components/Button";
import { SearchInput } from "../../components/Form";
import GridTableHeader from "../../components/common/table/GridTableHeader";
import AppWrapper from "../../layouts/AppWrapper";
import { useQuery } from "react-query";
import { fetchData } from "../../services/fetch";
import { AppContext } from "../../context/appContext";

const Drawchains = () => {
  const contents = translations.drawchains;
  const navigate = useNavigate();
  const { setUnexpectedErrorModal } = useContext(AppContext);

  const [filter, setFilter] = useState("");
  const [paginatedata, setPaginatedata] = useState({
    total_count: 0,
    page_count: 0,
    current_page_amount: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(1);

  const { isLoading, data: response } = useQuery(
    ["get-drawchains", page, filter],
    () =>
      fetchData({
        url: filter
          ? "api/draw-chain?filter=" + filter + "&page=" + page
          : "api/draw-chain?page=" + page,
      }),
    {
      keepPreviousData: true,
      onSuccess: async (response) => {
        const meta = response.data.draw_chains?.meta;
        let p = { ...paginatedata };
        p.total_count = meta.total;
        p.page_count = meta.last_page;
        if (meta.current_page * 10 > meta.total) {
          p.current_page_amount = meta.total;
        } else {
          p.current_page_amount = meta.current_page * 10;
        }
        p.current_page = meta.current_page;
        setPaginatedata(p);
      },
      onError: () => {
        setUnexpectedErrorModal(true);
      },
    }
  );

  const filterRecords = (value) => {
    if (page !== 1) {
      setPage(1);
      setFilter(value);
    } else {
      setFilter(value);
    }
  };

  const handlePagination = (paginateData) => {
    setPaginatedata(paginateData);
    setPage(paginateData.current_page);
  };

  const tableHeaderItem = [
    {
      headerName: contents.id,
      width: "17.5%",
      className: "text-start",
    },
    {
      headerName: contents.name,
      width: "30%",
    },
    {
      headerName: contents.perlia_title,
      width: "17.5%",
    },
    {
      headerName: contents.perlia_name,
      width: "17.5%",
    },
    {
      headerName: contents.created_at,
      width: "17.5%",
    },
  ];

  return (
    <AppWrapper bgMask={true}>
      {isLoading ? (
        <em>Loading...</em>
      ) : (
        <ContentWrapper
          titletopmarign="true"
          title={contents.title}
          topSpace={
            <div className="text-white pb-5-px d-flex justify-content-between h-100">
              <SearchInput
                placeholder={contents.placeholdersearch}
                width="551.52px"
                height="100%"
                onChange={filterRecords}
                value={filter}
                required
              />
              <Button
                text={contents.button}
                width="173px"
                onClick={() => navigate("/drawchains/create")}
              />
            </div>
          }
          bottomSpace={
            <Pagination
              onChange={handlePagination}
              paginate_data={paginatedata}
            />
          }
          noOverFlow="ture"
          marginLeft="12px"
        >
          <div className="h-100 w-100">
            <div className="table-responsive hide-scroll custom-table drawchain-table w-100">
              <table>
                <GridTableHeader tableHeaderItem={tableHeaderItem} />
                <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center mt-1">
                  {response.data?.draw_chains?.data.map((row, i) => (
                    <tr key={i}>
                      <td className="fs-14-px text-start font-ethno-light">
                        {row.drawchain_id}
                      </td>
                      <td className="fs-13-px">{row.title}</td>
                      <td className="fs-13-px">{row.group_name}</td>
                      <td className="fs-13-px">{row.persona_type_name}</td>
                      <td className="fs-13-px text-end d-flex justify-content-center align-items-center font-ethno-light">
                        <Link
                          to={`/drawchains/${row.id}`}
                          className="action-btn"
                        >
                          <span>{row.created_at.split("T")[0]}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </ContentWrapper>
      )}
    </AppWrapper>
  );
};

export default Drawchains;
