import "../assets/scss/switch.scss";

export const Switch = ({ id, value, onChange }) => {
  return (
    <div
      className="switch"
      data-id={id}
      role="switch"
      aria-checked={!!value}
      tabIndex="0"
      onClick={() => {
        const checkbox = document.getElementById(id);
        checkbox.checked = !checkbox.checked;
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          const checkbox = document.getElementById(id);
          checkbox.checked = !checkbox.checked;
        }
      }}
    >
      <input
        type="checkbox"
        id={id}
        style={{ visibility: "hidden" }}
        onChange={(e) => {
          const switchDiv = e.target.parentElement;
          switchDiv.setAttribute("aria-checked", e.target.checked);
          onChange?.(e);
        }}
        checked={!!value}
        value={value}
      />
      <label htmlFor={id}></label>
    </div>
  );
};
