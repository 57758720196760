import ContentWrapper from "../../layouts/ContentWrapper";
import { translations } from "../../services/localization";
import Pagination from "../../components/common/Pagination";
import { useEffect, useState } from "react";
import GridTableHeader from "../../components/common/table/GridTableHeader";
import { EditIcon } from "../../assets/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SearchInput } from "../../components/Form";
import Button from "../../components/Button";
import { fetchData } from "../../services/fetch";
import { storeErrorModal } from "../../services/storage";
import { useQuery } from "react-query";
import { formatNumber } from "../../utils/truncate";
const Personas = () => {
  const contents = translations.personas;
  const navigate = useNavigate();
  const [, setHideSideBar] = useOutletContext();

  const [filter, setFilter] = useState("");
  const [paginatedata, setPaginatedata] = useState({
    total_count: 0,
    page_count: 0,
    current_page_amount: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    setHideSideBar(false);
  }, [setHideSideBar]);

  const fetchPersonas = (page = 0, filter) =>
    fetchData({
      url: filter
        ? "api/persona_type?filter=" + filter + "&page=" + page
        : "api/persona_type?page=" + page,
    });

  const { isLoading, data: response } = useQuery(
    ["get-personas", page, filter],
    () => fetchPersonas(page, filter),
    {
      keepPreviousData: true,
      onSuccess: async (response) => {
        const meta = response.data.persona_types.meta;
        let p = { ...paginatedata };
        p.total_count = meta.total;
        p.page_count = meta.last_page;
        if (meta.current_page * 10 > meta.total) {
          p.current_page_amount = meta.total;
        } else {
          p.current_page_amount = meta.current_page * 10;
        }
        p.current_page = meta.current_page;
        setPaginatedata(p);
      },
      onError: (error) => {
        storeErrorModal(error.message);
      },
    }
  );

  const filterRecords = async (value) => {
    if (page !== 1) {
      setPage((p) => 1);
      setFilter(value);
    } else {
      setFilter(value);
    }
  };

  const handlePagination = (paginateData) => {
    setPaginatedata(paginateData);
    setPage(paginateData.current_page);
  };

  const tableHeaderItem = [
    {
      headerName: contents.no,
      width: "12%",
      className: "text-start",
    },
    {
      headerName: contents.name,
      width: "20%",
    },
    {
      headerName: contents.type_name,
      width: "18%",
    },
    {
      headerName: contents.published,
      width: "20%",
      className: "text-end",
    },
    {
      headerName: contents.price,
      width: "13%",
      className: "text-end",
    },
    {
      headerName: contents.action,
      width: "17%",
      className: "text-end",
    },
  ];

  if (isLoading) {
    return <em>Loading...</em>;
  }

  const handleAction = (persona_qty, id) => {
    if (persona_qty > 0) {
      navigate(`/personas/${id}`);
    } else {
      navigate(`/personas/update/${id}`);
    }
  };

  return (
    <ContentWrapper
      titletopmarign="true"
      title={contents.title}
      topSpace={
        <div className="text-white pb-5-px d-flex justify-content-between h-100">
          {/* Search Box */}
          {/* <form className="text-white px-2 pb-5-px d-flex justify-content-between h-100"> */}
          <div onKeyDown={() => {}}>
            <SearchInput
              placeholder={contents.placeholdersearch}
              // className="w-600-px"
              width="551.52px"
              height="100%"
              onChange={filterRecords}
              value={filter}
              required
            />
          </div>
          {/* </form> */}
          <Button
            text={contents.button}
            width="173px"
            onClick={() => {
              navigate("select");
            }}
          />
        </div>
      }
      bottomSpace={
        <Pagination onChange={handlePagination} paginate_data={paginatedata} />
      }
      noOverFlow="ture"
      marginLeft="12px"
    >
      <div className="h-100 w-100">
        <div className="table-responsive hide-scroll custom-table w-100">
          <table>
            <GridTableHeader tableHeaderItem={tableHeaderItem} />
            <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center mt-1">
              {response.data?.persona_types?.data.map((row, i) => (
                <tr key={i}>
                  <td className="font-ethno-light fs-14-px text-start">
                    {row.persona_type_no}
                  </td>
                  <td className="fs-15-px text-start">
                    {row.persona_type_name}
                  </td>
                  <td className="fs-15-px text-start">{row.group.name}</td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {row.publish_count} / {row.persona_qty}
                  </td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {formatNumber(row.jpy_amount)}
                  </td>
                  <td className="font-abj-choki fs-13-px text-end d-flex justify-content-end align-items-center">
                    <button
                      onClick={() => handleAction(row.persona_qty, row.id)}
                      className="action-btn d-flex justify-content-end align-items-center font-gkktt"
                    >
                      <EditIcon />
                      <span>{contents.detail}</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Personas;
