import { useContext, useEffect, useState } from "react";
import { SearchIcon } from "../assets/icons";
import { translations } from "../services/localization";
import { TextInput } from "./Form";
import { useQuery } from "react-query";
import { fetchData } from "../services/fetch";
import { AppContext } from "../context/appContext";

const ChoosePerria = ({ handleClick }) => {
  const contents = translations.choosePerria;
  const [filter, setFilter] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const { setUnexpectedErrorModal } = useContext(AppContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(filter);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  const { isLoading, data: response } = useQuery(
    ["get-titles", debouncedQuery],
    () =>
      fetchData({
        url: debouncedQuery
          ? "api/persona_group?filter=" + debouncedQuery
          : "api/persona_group",
      }),
    {
      keepPreviousData: true,
      onError: (error) => {
        setUnexpectedErrorModal(true);
      },
    }
  );

  return (
    <section className="d-flex flex-column align-items-center w-100 h-100 p-35-px position-relative">
      <h1 className="fs-25-px font-ethno-light">{contents.title}</h1>
      {isLoading ? (
        <em>Loading...</em>
      ) : (
        <>
          <div className="pt-20-px pb-15-px">
            <TextInput
              width="400px"
              inputClass="fs-20-px text-start"
              borderColor="#e8723cb3"
              inactive
              leftIcon={<SearchIcon />}
              placeholder={contents.placeholder}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <article className="table-responsive hide-scroll choose-perria-table">
            <table>
              <thead>
                <tr>
                  <th>{contents.id}</th>
                  <th>{contents.name}</th>
                </tr>
              </thead>
              <tbody>
                {response.data?.groups.map((row, i) => (
                  <tr
                    onClick={() => {
                      handleClick(row.id, row.name);
                    }}
                    key={i}
                  >
                    <td>{row.group_no}</td>
                    <td>{row.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </article>
        </>
      )}
    </section>
  );
};

export default ChoosePerria;
