import { useNavigate } from "react-router-dom";
import Button, { CancelButton } from "../../components/Button";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { translations } from "../../services/localization";
import { ConfirmModal, CustomModal, InfoModal } from "../../components/Modal";
import { useContext, useState } from "react";
import ChoosePerria from "../../components/ChoosePerria";
import { NumberInput, SelectInput, TextInput } from "../../components/Form";
import {
  ABSIcon,
  CopyIcon,
  DFTIcon,
  EXPIcon,
  FORIcon,
  ImgPlaceholderIcon,
  INTIcon,
  MNDIcon,
  PenIcon,
} from "../../assets/icons";
import { Switch } from "../../components/Switch";
import { fetchData, fetchFormData } from "../../services/fetch";
import { useMutation, useQuery } from "react-query";
import { AppContext } from "../../context/appContext";

const DrawchainCreate = () => {
  const contents = translations.drawchain;
  const navigate = useNavigate();
  const { setUnexpectedErrorModal } = useContext(AppContext);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showChooseModal, setShowChooseModal] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    message: "",
    goToList: false,
  });

  const [status, setStatus] = useState(1);
  const [parameters, setParameters] = useState({
    for: "",
    abs: "",
    def: "",
    month: "",
    int: "",
    exp: "",
  });
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [byMail, setByMail] = useState(false);
  const [isBrun, setIsBurn] = useState(false);
  const [times, setTimes] = useState("");
  const [perria, setPerria] = useState({
    id: "",
    name: "",
  });
  const [image, setImage] = useState({ local: "", upload: null, hash: "" });
  const [vImage, setVImage] = useState({ local: "", upload: null, hash: "" });
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [personas, setPersonas] = useState(null);
  const [perliaId, setPerliaId] = useState("");

  const { isLoading: isLoadingPersonas } = useQuery(
    ["get-personas", perria.id],
    () =>
      fetchData({
        url: "api/persona_type?group_id=" + perria.id,
      }),
    {
      onSuccess: async (response) => {
        if (response?.data?.persona_types?.data.length > 0) {
          const personas = {};
          response.data.persona_types.data.forEach((p) => {
            personas[p.persona_type_name] = p.id;
          });
          setPersonas(personas);
        }
      },
      onError: (error) => {
        console.log(error);
        setModal({
          show: true,
          message: "Error in the loading personas",
        });
      },
      enabled: !!perria.id,
    }
  );

  const handleChangeParameter = (prop) => (e) => {
    setParameters({ ...parameters, [prop]: e.target.value });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/json"];
      if (!allowedTypes.includes(file.type)) {
        setModal({
          show: true,
          message: (
            <>
              アップロードできるファイル形式は jpg、png、json <br />
              のみです。
            </>
          ),
        });
        return;
      }
      if (file.size > 2 * 1000 ** 2) {
        setModal({
          show: true,
          message: contents.validation.imageSize,
        });
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImage({ local: imageUrl, upload: file, hash: "" });
    }
  };

  const handleVImageChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/json"];
      if (!allowedTypes.includes(file.type)) {
        setModal({
          show: true,
          message: (
            <>
              アップロードできるファイル形式は jpg、png、json <br />
              のみです。
            </>
          ),
        });
        return;
      }
      if (file.size > 2 * 1000 ** 2) {
        setModal({
          show: true,
          message: contents.validation.imageSize,
        });
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setVImage({ local: imageUrl, upload: file, hash: "" });
    }
  };

  const handleSelectPerria = (id, name) => {
    setPerria({ id, name });
    setPerliaId("");
    setShowChooseModal(false);
  };

  const { mutate: createDrawchain } = useMutation(
    async (data) => {
      return await fetchFormData({
        url: "api/draw-chain",
        data: data,
      });
    },
    {
      onError: async (err) => {
        console.log(err.response.data.errors);
        setUnexpectedErrorModal(true);
      },
      onSuccess: async () => {
        setModal({
          show: true,
          message: "保存しました。",
          goToList: true,
        });
      },
      onSettled: () => {
        setIsCreateLoading(false);
      },
    }
  );

  const handleCreate = async () => {
    setIsCreateLoading(true);
    if (
      !parameters.for ||
      !parameters.abs ||
      !parameters.def ||
      !parameters.month ||
      !parameters.int ||
      !parameters.exp ||
      !times ||
      !name ||
      !description ||
      !image.upload ||
      !vImage.upload
    ) {
      setModal({ show: true, message: "必須項目を埋めてください。" });
      setIsCreateLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("for", parameters.for);
    formData.append("abs", parameters.abs);
    formData.append("dft", parameters.def);
    formData.append("mnd", parameters.month);
    formData.append("int", parameters.int);
    formData.append("exp", parameters.exp);
    formData.append("persona_type_id", perliaId);
    formData.append("title", name);
    formData.append("descritpion", description);
    formData.append("draw_count", times);
    formData.append("can_draw", status);
    formData.append("can_mail", Number(byMail));
    formData.append("burn", Number(isBrun));
    formData.append("image", image.upload);
    formData.append("background_image", vImage.upload);
    createDrawchain(formData);
  };

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={contents.title}
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() => setShowCancelModal(true)}
            />
            <Button
              text={contents.keep_btn}
              width="173px"
              height="40px"
              onClick={handleCreate}
              disabled={isCreateLoading}
            />
          </div>
        }
        marginLeft="12px"
      >
        {false ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <h2 className="">
              <span className="fs-18-px font-notosans-jp text-white mr-30-px">
                {contents.sub_title}
              </span>
              <span className="fs-14-px font-notosans-jp-light text-light-primary">
                {contents.sub_title_secondary}
              </span>
            </h2>
            <section className="ml-18-px">
              <p className="fs-14-px text-danger my-25-px">{contents.notice}</p>
              <div className="d-flex align-items-center mb-30-px">
                <div className="d-flex align-items-center gap-30-px flex-grow-1">
                  <label className="font-notosans-jp label-text">
                    {contents.perlia_title}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  {/* <TextInput
                    width="196px"
                    className="button-input"
                    inputClass="text-center text-highlight fs-14-px text-form-control font-ethno-light"
                    inactive
                    value={perria.name || contents.perlia_title_placeholder}
                    type="button"
                    valueExist={perria.name ? 1 : 0}
                    onClick={() => setShowChooseModal(true)}
                  /> */}
                  <div className="button-select-persona">
                    <button
                      onClick={() => setShowChooseModal(true)}
                      className="text-highlight fs-12-px font-ethno-normal text-truncate"
                    >
                      {perria.name || contents.perlia_title_placeholder}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-30-px flex-grow-1">
                  <label className="font-notosans-jp label-text">
                    {contents.perlia_name}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <SelectInput
                    id="perlia-name"
                    value={perliaId}
                    onChange={(e) => {
                      setPerliaId(e.target.value);
                    }}
                    options={personas ?? {}}
                    placeholder={contents.perlia_name_placeholder}
                    styles={{ width: 196 }}
                    className="text-highlight text-center fs-13-px font-ethno-normal center-placeholder"
                    disabled={isLoadingPersonas || !personas}
                  />
                </div>
              </div>
              <section className={`fade-in-out ${!!perliaId ? "show" : ""}`}>
                <div className="d-flex align-items-center mb-30-px">
                  <label className="font-notosans-jp label-text">
                    {contents.id}
                  </label>
                  <p
                    className="font-ethno-light fs-18-px text-gray ml-100-px"
                    onClick={() => {
                      // navigator.clipboard.writeText(128849281025);
                      // setModal({
                      //   show: true,
                      //   message: "クリップボードにコピーしました。",
                      // });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span style={{ letterSpacing: "0.4em" }}>------------</span>
                    <CopyIcon />
                  </p>
                  <p className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                    {contents.auto}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-30-px">
                  <div className="d-flex align-items-center gap-30-px flex-grow-1">
                    <label className="font-notosans-jp label-text mr-20-px">
                      {contents.status}
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <SelectInput
                      id="status"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      options={{
                        OPEN: 1,
                        CLOSED: 0,
                        FINISHED: 2,
                      }}
                      placeholder={contents.status}
                      styles={{ width: 196 }}
                      className="text-highlight text-center fs-13-px font-ethno-normal center-placeholder"
                    />
                    <span className="fs-14-px font-notosans-jp-light text-light-primary">
                      {contents.status_secondary}
                    </span>
                  </div>
                </div>
                <div className="mb-30-px">
                  <label className="font-notosans-jp label-text my-2 mr-30-px">
                    {contents.required_status}
                    <span className="text-danger required-label ml-50-px fs-18-px">
                      *
                    </span>
                  </label>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <FORIcon /> FOR
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.for}
                      onChange={handleChangeParameter("for")}
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <ABSIcon /> ABS
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.abs}
                      onChange={handleChangeParameter("abs")}
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <DFTIcon /> DFT
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.def}
                      onChange={handleChangeParameter("def")}
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <MNDIcon /> MND
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.month}
                      onChange={handleChangeParameter("month")}
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <INTIcon /> INT
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.int}
                      onChange={handleChangeParameter("int")}
                    />
                  </div>
                  <div className="d-flex align-items-center my-3 ml-35-px">
                    <label className="font-notosans-jp label-text fs-20-px w-10 mr-10-px">
                      <EXPIcon /> EXP
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </label>
                    <NumberInput
                      width="140.46px"
                      inputClass="text-form-control-value text-center font-ethno-normal fs-17-px large-placeholder"
                      inactive
                      placeholder="---"
                      rightIcon={<PenIcon />}
                      value={parameters.exp}
                      onChange={handleChangeParameter("exp")}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-30-px">
                  <label className="font-notosans-jp label-text mr-50-px">
                    {contents.no_of_draw}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <NumberInput
                    width="112px"
                    inputClass="fs-20-px center-placeholder text-center"
                    inactive
                    rightIcon={<PenIcon />}
                    placeholder={contents.no_of_draw_placeholder}
                    value={times}
                    onChange={(e) => {
                      setTimes(e.target.value);
                    }}
                  />
                  <p className="fs-17-px text-white ml-10-px">
                    {contents.times}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-30-px">
                  <label className="font-notosans-jp label-text mr-50-px">
                    {contents.by_mail}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <div className="ml-50-px">
                    <span
                      className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                      style={{ width: 110 }}
                    >
                      ON / OFF
                    </span>
                    <Switch
                      id="by-mail"
                      value={byMail}
                      onChange={(e) => setByMail(e.target.checked)}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-30-px">
                  <label className="font-notosans-jp label-text mr-35-px">
                    {contents.is_burn}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <div>
                    <span
                      className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                      style={{ width: 100 }}
                    >
                      しない
                    </span>
                    <Switch
                      id="is_burn"
                      value={isBrun}
                      onChange={(e) => {
                        setIsBurn(e.target.checked);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-30-px">
                  <label className="font-notosans-jp label-text mr-50-px">
                    {contents.name}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <TextInput
                    width="416px"
                    className="ml-50-px"
                    inputClass="fs-15-px"
                    inactive
                    rightIcon={<PenIcon />}
                    placeholder={contents.name_placeholder}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="d-flex align-items-start mb-30-px gap-100-px">
                  <div>
                    <p className="font-notosans-jp label-text mb-20-px">
                      {contents.thumbnail}
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </p>
                    <input
                      type="file"
                      id="thumbnail"
                      style={{ visibility: "hidden", display: "none" }}
                      onChange={handleImageChange}
                      aria-hidden="true"
                    />
                    <div
                      className="drawchain-thumbnail-container img-container"
                      onClick={() => {
                        document.getElementById("thumbnail").click();
                      }}
                      tabIndex="0"
                      role="button"
                      aria-label="Select thumbnail"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          document.getElementById("thumbnail").click();
                        }
                      }}
                    >
                      {image.local ? (
                        <img src={image.local} alt="Selected thumbnail" />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center flex-column h-100">
                          <ImgPlaceholderIcon />
                          <p className="w-40 my-20-px text-center text-gray fs-13-px">
                            {contents.thumbnail_placeholder}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="font-notosans-jp label-text mb-20-px">
                      {contents.vertical_thumbnail}
                      <span className="text-danger required-label ml-10-px fs-18-px">
                        *
                      </span>
                    </p>
                    <input
                      type="file"
                      id="v-thumbnail"
                      style={{ visibility: "hidden", display: "none" }}
                      onChange={handleVImageChange}
                      aria-hidden="true"
                    />
                    <div
                      className="drawchain-v-thumbnail-container img-container"
                      onClick={() => {
                        document.getElementById("v-thumbnail").click();
                      }}
                      tabIndex="0"
                      role="button"
                      aria-label="Select vertical thumbnail"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          document.getElementById("v-thumbnail").click();
                        }
                      }}
                    >
                      {vImage.local ? (
                        <img src={vImage.local} alt="Selected thumbnail" />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center flex-column h-100">
                          <ImgPlaceholderIcon width="63.31" height="52.46" />
                          <p className="w-80 my-20-px text-center text-gray fs-13-px">
                            {contents.vertical_thumbnail_placeholder}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-30-px">
                  <label className="font-notosans-jp label-text mr-50-px mt-10-px">
                    {contents.description} <br />
                    {contents.description_secondary}
                    <span className="text-danger required-label ml-10-px fs-18-px">
                      *
                    </span>
                  </label>
                  <TextInput
                    className="ml-30-px"
                    inputClass="fs-16-px"
                    isTextArea
                    width="641.1px"
                    height="118px"
                    inactive
                    rightIcon={<PenIcon />}
                    placeholder={contents.description_placeholder}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </section>
            </section>
          </div>
        )}
      </ContentWrapper>
      <ConfirmModal
        isOpen={showCancelModal}
        onConfirm={() => {
          setShowCancelModal(false);
          handleCreate();
        }}
        onCancel={() => {
          setTimeout(() => {
            navigate("/drawchains");
          }, 500);
          setShowCancelModal(false);
        }}
      >
        一部の変更は保存されていません。
        <br />
        変更を保存しますか？
      </ConfirmModal>
      <CustomModal
        isOpen={showChooseModal}
        setIsOpen={setShowChooseModal}
        topAction
        cancelText={contents.cancel_btn}
        width="589.2px"
        height="472.6px"
      >
        <ChoosePerria handleClick={handleSelectPerria} />
      </CustomModal>
      <InfoModal
        isOpen={modal.show}
        onAction={() => {
          if (modal.goToList) {
            setTimeout(() => {
              navigate("/drawchains");
            }, 500);
          }
          setModal({ ...modal, show: false, goToList: false });
        }}
      >
        {modal.message}
      </InfoModal>
    </AppWrapper>
  );
};

export default DrawchainCreate;
