import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import { useNavigate, useParams } from "react-router-dom";
import Button, { CancelButton } from "../../components/Button";
import { TextInput } from "../../components/Form";
import {
  ABSIcon,
  BottomLessAbyssIcon,
  CopyIcon,
  CrimsonFlameIcon,
  DFTIcon,
  DrawChainIcon,
  DropOfHeavenIcon,
  EatIcon,
  EverGreenTreeIcon,
  EXPIcon,
  FORIcon,
  GoldenLightIcon,
  INTIcon,
  MNDIcon,
  PenIcon,
  PersonaIcon,
  SolidRockIcon,
  UnrivaledSteelIcon,
  UpdateIcon,
  UploadIcon,
} from "../../assets/icons";
import { Switch } from "../../components/Switch";
import { CustomModal, InfoModal, LoadingModal } from "../../components/Modal";
import { useContext, useState } from "react";
import { fetchData } from "../../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../../services/storage";
import { useDispatch } from "react-redux";
import { setSelectPersona } from "../../slices/personaReducer";
import { PersonaQtyUpdate } from "../../components/PersonaQtyUpdate";
import { AuthContext } from "../../context/authContext";
import { formatNumber } from "../../utils/truncate";

const tags = { tag_1: false, tag_2: false, tag_3: false };
const tags_en = { tag_en_1: false, tag_en_2: false, tag_en_3: false };

const PersonaDetail = () => {
  const contents = translations.personaDetail;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const [showQtyModal, setShowQtyModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [isLoadProgress, setIsLoadProgress] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [validModal, setValidModal] = useState({ show: false, message: "" });

  const fetchPersona = (id) =>
    fetchData({
      url: `api/persona_type/${id}`,
    });

  const { isLoading, data: response } = useQuery(
    ["get-persona", id],
    () => fetchPersona(id),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        const persona = data?.data?.persona_type;
        for (const t in tags) {
          tags[t] = !!persona[t];
          tags_en[`tag_en_${t.slice(-1)}`] = !!persona[`tag_en_${t.slice(-1)}`];
        }
      },
    }
  );

  const fetchProgress = () =>
    fetchData({
      url: `api/job-batches/${batchId}/progress`,
    });

  const { data: progressData } = useQuery(
    ["get-persona-progress", batchId],
    () => fetchProgress(),
    {
      onError: (error) => {
        storeErrorModal(error.message);
      },
      onSuccess: (data) => {
        if (data.data.percentage === 100) {
          setIsLoadProgress(false);
        }
      },
      refetchInterval: isLoadProgress ? 5000 : false,
      enabled: isLoadProgress,
    }
  );

  const persona = !isLoading ? response.data.persona_type : null;

  if (persona && persona.persona_token_count === 0) {
    navigate(`/personas/update/${persona.id}`);
  }

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() => navigate("/personas")}
            />
            <Button
              text={contents.update_btn}
              width="173px"
              height="40px"
              onClick={() => {
                dispatch(setSelectPersona(persona));
                navigate(`/personas/up/${id}`);
              }}
              isRightIcon={true}
              textClassName="m-0"
              rightIcon={<UpdateIcon className="mr-5-px" />}
              disabled={!persona}
            />
          </div>
        }
        marginLeft="12px"
      >
        {!persona ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <section className="row mb-3">
              <div className="col row">
                <p className="font-notosans-jp label-text col-4">
                  {contents.title_name}
                </p>
                <p className="fs-17-px text-gray col-8">{persona.group.name}</p>
              </div>
              <div className="col row">
                <p className="font-notosans-jp label-text col-3">
                  {contents.token_id}
                </p>
                <div className="col-9">
                  <p
                    className="font-ethno-light fs-18-px text-gray"
                    onClick={() => {
                      navigator.clipboard.writeText(persona.persona_type_no);
                      setShowCopyModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {persona.persona_type_no} <CopyIcon />
                  </p>
                  <p className="font-notosans-jp fs-14-px text-light-primary">
                    {contents.token_id_secondary}
                  </p>
                </div>
              </div>
            </section>
            <section className="row mb-5">
              <p className="font-notosans-jp label-text col-2">
                {contents.name}
              </p>
              <p className="fs-17-px text-gray col">
                {persona.persona_type_name}
              </p>
            </section>
            <section className="row mb-5">
              <p className="font-notosans-jp label-text col-2">
                {contents.creator_name}
              </p>
              <p className="fs-17-px text-gray col">{persona.creator}</p>
            </section>
            <section className="row mb-5">
              <div className="col-3">
                <p className="font-notosans-jp label-text mb-3">
                  {contents.quantity}
                </p>
                <div className="outline-btn">
                  <div className="bg"></div>
                  <button onClick={() => setShowQtyModal(true)}>
                    <span>{persona.persona_token_count}</span>
                    <div className="right-icon">
                      <span className="person-icon">
                        <PersonaIcon />
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-3">
                <p className="font-notosans-jp label-text mb-3">
                  {contents.published}
                </p>
                <div className="outline-btn">
                  <div className="bg"></div>
                  <button
                    onClick={() => {
                      dispatch(setSelectPersona(persona));
                      navigate(`/personas/market/${persona.id}`);
                    }}
                  >
                    <span>{persona.publish_persona_token_count}</span>
                    <div className="right-icon">
                      <span className="upload-icon">
                        <UploadIcon />
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col ml-50-px">
                <p className="font-notosans-jp label-text text-center mb-3">
                  {contents.price}
                </p>
                <div className="d-flex">
                  <p className="font-ethno-normal fs-17-px text-highlight flex-grow-1">
                    {formatNumber(persona.jpy_amount) ?? "---"} JPY
                  </p>
                  <p className="font-ethno-normal fs-17-px text-highlight flex-grow-1">
                    {formatNumber(persona.anm_amount) ?? "---"} ANM
                  </p>
                </div>
              </div>
            </section>
            <section className="d-flex mb-5">
              <div className="">
                <p
                  className="font-notosans-jp label-text mb-4"
                  style={{ height: 40, alignContent: "center" }}
                >
                  {contents.image}
                </p>
                <div className="persona-img-container img-container">
                  <img src={persona.image_uri} alt="" />
                </div>
              </div>
              <div className="ms-5 w-100">
                <p
                  className="font-notosans-jp label-text mb-4"
                  style={{ height: 40, alignContent: "center" }}
                >
                  {contents.parameter}
                  <span className="fs-14-px text-light-primary ml-50-px">
                    {contents.parameter_secondary}
                  </span>
                </p>
                <div className="ml-20-px">
                  {persona.custom_parameter1 &&
                    persona.custom_parameter1.length > 0 &&
                    persona.custom_parameter1.map((p, index) => (
                      <div
                        className="d-flex my-3 align-items-center"
                        key={index}
                      >
                        <label className="label-text" style={{ width: 270 }}>
                          {p.name}
                        </label>
                        <TextInput
                          width="270px"
                          inputClass="text-center left-placeholder fs-17-px"
                          inactive
                          disabled
                          value={
                            (!isNaN(Number(p.value))
                              ? formatNumber(p.value)
                              : p.value) ?? ""
                          }
                        />
                      </div>
                    ))}
                  {/* <div className="row my-3 align-items-center">
                    <label className="label-text col-4">
                      {contents.political_power}
                    </label>
                    <TextInput
                      width="270px"
                      inputClass="text-center left-placeholder fs-17-px"
                      inactive
                    />
                  </div>
                  <div className="row my-3 align-items-center">
                    <label className="label-text col-4">
                      {contents.leg_strength}
                    </label>
                    <TextInput
                      width="270px"
                      inputClass="text-center left-placeholder fs-17-px"
                      inactive
                    />
                  </div>
                  <div className="row my-3 align-items-center">
                    <label className="label-text col-4">
                      {contents.charisma}
                    </label>
                    <TextInput
                      width="270px"
                      inputClass="text-center left-placeholder fs-17-px"
                      inactive
                    />
                  </div> */}
                </div>
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.description}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.description_secondary}
                </span>
              </label>
              <TextInput
                className="ml-30-px"
                isTextArea
                width="965px"
                height="118px"
                inputClass="left-placeholder"
                inactive
                placeholder={contents.description_placeholder}
                value={persona.description ?? ""}
                disabled
              />
            </section>
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.tag}
                <span className="text-danger required-label ml-10-px fs-18-px">
                  *
                </span>
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.tag_secondary}
                </span>
              </p>
              <div className="d-flex align-items-center ml-20-px">
                <p className="text-white fs-18-px w-2">JP</p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "1rem",
                  }}
                  className="ml-15-px"
                >
                  {Object.keys(tags).map((tag, index) => {
                    if (!tags[tag]) return null;
                    return (
                      <div key={index} className="position-relative">
                        <TextInput
                          leftIcon={<span className="text-white">#</span>}
                          width="300px"
                          inputClass="fs-17-px text-form-control text-center large-placeholder pl-30-px"
                          inactive
                          isRightIcon={true}
                          rightIcon={<PenIcon />}
                          value={persona[tag]}
                          disabled
                        />
                        <span className="center-vertical-line"></span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex align-items-center ml-20-px mt-30-px">
                <p className="text-white fs-18-px w-2">EN</p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "1rem",
                  }}
                  className="ml-15-px"
                >
                  {Object.keys(tags_en).map((tag, index) => {
                    if (!tags_en[tag]) return null;
                    return (
                      <TextInput
                        key={index}
                        leftIcon={<span className="text-white">#</span>}
                        width="300px"
                        inputClass="fs-17-px text-form-control text-center large-placeholder pl-30-px"
                        inactive
                        isRightIcon={true}
                        rightIcon={<PenIcon />}
                        value={persona[tag]}
                        disabled
                      />
                    );
                  })}
                </div>
              </div>
            </section>
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.status}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.status_secondary}
                </span>
              </p>
              <div className="row pl-30-px w-85">
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <FORIcon /> FOR
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    value={persona.for_parameter}
                    disabled
                  />
                </div>
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <ABSIcon /> ABS
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    disabled
                    value={persona.abs_parameter}
                  />
                </div>
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <DFTIcon /> DFT
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    disabled
                    value={persona.def_parameter}
                  />
                </div>
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <MNDIcon /> MND
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    disabled
                    value={persona.month_parameter}
                  />
                </div>
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <INTIcon /> INT
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    disabled
                    value={persona.int_parameter}
                  />
                </div>
                <div className="col-2">
                  <label className="font-notosans-jp label-text ml-10-px fs-20-px">
                    <EXPIcon /> EXP
                  </label>
                  <TextInput
                    width="110px"
                    inputClass="font-ethno-normal left-placeholder text-highlight fs-20-px text-center"
                    inactive
                    disabled
                    value={persona.exp_parameter}
                  />
                </div>
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text">
                {contents.cost}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.cost_secondary}
                </span>
              </label>
              <p className="font-ethno-normal fs-20-px text-highlight ml-30-px my-3">
                {persona.required_anima_amount}
              </p>
            </section>
            {/* <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.absorb.label}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.absorb_secondary}
                </span>
              </p>
              <div className="pl-30-px d-flex">
                <label className="label-text fs-18-px w-30">
                  <EatIcon className="mr-10-px" /> {contents.absorb.contract}
                </label>
                <div>
                  <span
                    className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                    style={{ width: 50 }}
                  >
                    OFF
                  </span>
                  <Switch id="absorb" />
                </div>
              </div>
            </section>
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.draw_chain.label}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.draw_chain_secondary}
                </span>
              </p>
              <div className="d-flex pl-30-px">
                <label className="label-text fs-18-px col-4 w-30">
                  <DrawChainIcon className="mr-5-px" />
                  {contents.draw_chain.contract}
                </label>
                <div className="">
                  <span
                    className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end fs-17-px"
                    style={{ width: 50 }}
                  >
                    ON
                  </span>
                  <Switch id="drawChain" />
                </div>
              </div>
              <section className="ml-30-px pl-33-px">
                <div className="d-flex mt-4 align-items-center">
                  <label className="label-text fs-18-px w-29">
                    {contents.draw_chain.id}
                  </label>
                  <div className="p-0">
                    <p className="font-ethno-light fs-18-px text-gray">
                      <span className="fs-19-px">
                        128849281025 <CopyIcon />
                      </span>
                      <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                        {contents.token_id_secondary}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <label className="label-text fs-18-px w-25">
                    {contents.draw_chain.name}
                  </label>
                  <div className="p-0">
                    <TextInput
                      width="415px"
                      inputClass="left-placeholder text-form-control"
                      inactive
                      isRightIcon={true}
                      rightIcon={<PenIcon />}
                      placeholder={contents.draw_chain.name_placeholder}
                    />
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <label className="label-text fs-18-px d-flex pr-0-px w-25">
                    <span>{contents.draw_chain.required}</span>
                    <span
                      className="fs-20-px d-flex align-items-center"
                      style={{ marginLeft: "10%" }}
                    >
                      <FORIcon /> FOR
                    </span>
                  </label>
                  <div className="p-0 d-flex align-items-center">
                    <TextInput
                      className="d-inline-block"
                      width="111px"
                      inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                      inactive
                      isRightIcon={true}
                      rightIcon={<PenIcon />}
                      placeholder={contents.draw_chain.placeholder}
                    />
                    <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                      {contents.draw_chain.required_secondary}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <label className="col-3 label-text fs-18-px w-25">
                    {contents.draw_chain.no}
                  </label>
                  <div className="p-0 d-flex align-items-center">
                    <TextInput
                      className="d-inline-block"
                      width="111px"
                      inputClass="left-placeholder text-form-control font-ethno-normal text-center text-gray"
                      inactive
                      isRightIcon={true}
                      rightIcon={<PenIcon />}
                      placeholder={contents.draw_chain.placeholder}
                    />
                    <span className="font-notosans-jp fs-17-px text-gray ml-10-px">
                      {contents.draw_chain.no_secondary}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <label className="label-text fs-18-px pt-10-px w-25">
                    {contents.draw_chain.description}
                  </label>
                  <div className="p-0 w-75">
                    <TextInput
                      isTextArea
                      width="710px"
                      height="118px"
                      inputClass="left-placeholder text-form-control fs-17-px"
                      inactive
                      isRightIcon={true}
                      rightIcon={<PenIcon />}
                      placeholder={contents.draw_chain.description_placeholder}
                    />
                  </div>
                </div>
              </section>
            </section> */}
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.element.label}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.element_secondary}
                </span>
              </p>
              <div className="row pl-30-px">
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <DropOfHeavenIcon className="mr-10-px" />
                      天の雫
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.heavenly_dew_element}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <EverGreenTreeIcon className="mr-10-px" />
                      常磐なる樹
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.eternal_tree_element}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <SolidRockIcon className="mr-10-px" />
                      堅牢な岩
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.adamantine_rock_element}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <CrimsonFlameIcon className="mr-10-px" />
                      紅蓮の焔
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.crimson_flame_element}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <UnrivaledSteelIcon className="mr-10-px" />
                      無双の鋼
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.peerless_steel_element}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <GoldenLightIcon className="mr-10-px" />
                      黄金の光
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.golden_light_element}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text col-7">
                      <BottomLessAbyssIcon className="mr-10-px" />
                      底知れぬ深淵
                    </label>
                    <div className="col-5">
                      <TextInput
                        width="110px"
                        inputClass="text-center fs-17-px large-placeholder"
                        inactive
                        placeholder="---"
                        disabled
                        value={persona.unfathomable_abyss_element}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.place_of_birth}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.place_of_birth_secondary}
                </span>
              </label>
              <p className="font-ethno-normal fs-19-px text-gray ml-30-px">
                {user?.tenant?.birthplace}
              </p>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.peerage}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.peerage_secondary}
                </span>
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.peerage_third}
                </span>
              </label>
              <TextInput
                className="ml-30-px"
                width="307.92px"
                inputClass="font-ethno-normal text-gray fs-17-px left-placeholder"
                inactive
                value={persona.peerage ?? ""}
                disabled
              />
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.parameter_ability}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.parameter_ability_secondary}
                </span>
              </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                {persona.custom_parameter2 &&
                  persona.custom_parameter2.map((p, index) => (
                    <TextInput
                      key={index}
                      className="ml-30-px"
                      width="300px"
                      inputClass="text-center fs-17-px left-placeholder"
                      inactive
                      value={p ?? ""}
                      disabled
                    />
                  ))}
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.parameter_title}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.parameter_title_secondary}
                </span>
              </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                {persona.custom_parameter3 &&
                  persona.custom_parameter3.map((p, index) => (
                    <TextInput
                      key={index}
                      className="ml-30-px"
                      width="300px"
                      inputClass="text-center fs-17-px left-placeholder"
                      inactive
                      value={p ?? ""}
                      disabled
                    />
                  ))}
              </div>
            </section>
          </div>
        )}
        <CustomModal isOpen={showQtyModal} height={673.02} width={805.64}>
          <PersonaQtyUpdate
            setShowQtyModal={setShowQtyModal}
            personaId={persona?.id}
            setIsLoadProgress={setIsLoadProgress}
            setShowLoadingModal={setShowLoadingModal}
            personaToken={persona?.persona_token_count}
            setBatchId={setBatchId}
            setValidModal={setValidModal}
          />
        </CustomModal>
        <InfoModal
          isOpen={showCopyModal}
          onAction={() => setShowCopyModal(false)}
        >
          クリップボードにコピーしました。
        </InfoModal>
        <LoadingModal
          isOpen={showLoadingModal}
          onAction={() => {
            setShowLoadingModal(false);
            setTimeout(() => {
              navigate("/personas");
            }, 500);
          }}
          progress={progressData?.data?.percentage ?? 0}
        ></LoadingModal>
        <InfoModal
          isOpen={validModal.show}
          onAction={() => {
            setValidModal({ ...validModal, show: false });
          }}
        >
          {validModal.message}
        </InfoModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default PersonaDetail;
